import React, { useState, useEffect, useRef } from 'react';
import RightAsideLayout from '../../layouts/RightAsideLayout/RightAsideLayout';
import AsideModuleSelector from '../../containers/course/AsideModuleSelector/AsideModuleSelector';
import TextAreaEditor from '../../components/molecules/TextAreaEditor/TextAreaEditor';
import Icon from '../../components/atoms/Icon/Icon';
import { useHistory } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom';
import {
  actions as NavigationActions,
  selectors as NavigationSelectors,
} from '../../redux/NavigationRedux';
import { selectors as ConsultantSelectors } from '../../redux/ConsultantRedux';
import { selectors as UserSelectors } from '../../redux/UserRedux';
import { selectors as OfficeSelectors } from '../../redux/OfficeRedux';
import { selectors as BeneficiarySelectors } from '../../redux/BeneficiaryRedux';
import { connect } from 'react-redux';
import Page from '../../containers/global/Page/Page';
import { getModule } from '../../api/CourseApi';
import './ConsultantCourseFormViewPage.scss';
import QuizStep from '../../components/molecules/QuizStep/QuizStep';
import QuizTextArea from '../../components/molecules/QuizTextArea/QuizTextArea';
import QuizCombo from '../../components/molecules/QuizCombo/QuizCombo';
import QuizQcm from '../../components/molecules/QuizQcm/QuizQcm';
import QuizGroup from '../../components/molecules/QuizGroup/QuizGroup';
import QuizYesNo from '../../components/molecules/QuizYesNo/QuizYesNo';
import QuizStars from '../../components/molecules/QuizStars/QuizStars';
import QuizOpinionScale from '../../components/molecules/QuizOpinionScale/QuizOpinionScale';
import QuizDate from '../../components/molecules/QuizDate/QuizDate';
// import ExportContainerTinyMCE from '../../components/organisms/ExportContainerTinyMCE/ExportContainerTinyMCE';
import PrimaryButton from '../../components/atoms/PrimaryButton/PrimaryButton';

const mapStateToProps = (state) => ({
  user: UserSelectors.user(state),
  consultant: ConsultantSelectors.consultant(state),
  office: OfficeSelectors.office(state),
  pathname: NavigationSelectors.pathname(state),
  beneficiary: BeneficiarySelectors.beneficiary(state),
});

const mapDispatchToProps = (dispatch) => ({
  back: (prefix = '') => dispatch(NavigationActions.back(prefix, true)),
  redirect: (pathname) => dispatch(NavigationActions.push(pathname)),
});

const ConsultantCourseFormViewPage = (props) => {
  const tabs = [
    { id: 'new', label: 'Nouveau' },
    { id: 'existing', label: 'Existant' },
  ];
  const [modulesType, setModulesType] = useState([]);
  const [isOpenModalEnterQuestion, setIsOpenModalEnterQuestion] = useState(false);
  const [isOpenModalEnterTitleQuiz, setIsOpenModalEnterTitleQuiz] = useState(false);
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState('');
  const [questions, setQuestions] = useState([]);
  const history = useHistory();
  const match = useRouteMatch();
  const [isTitle, setIsTitle] = useState(false);
  const [module, setModule] = useState(null);
  const [questionsType, setQuestionsType] = useState([]);

  const fetchModule = async () => {
    const response = await getModule(parseInt(match.params.module_id));
    setModule(response.module);
  };

  useEffect(() => {
    if (match.params.module_id) {
      fetchModule();
    }
  }, [match]);

  useEffect(() => {
    if (module) {
      setQuestions(module.data[0].module_form_questions);
    }
  }, [module]);

  const goBack = () => {
    history.push({
      pathname: `/consultant/templates/courses/${match.params.course_id}`,
      state: module.modules_container_id,
    });
  };

  const renderHeader = () => {
    return (
      <div className="form-edit-page-header">
        <div className="form-edit-page-title">
          <button onClick={goBack}>
            <Icon icon="back" color={'accent'} />
          </button>
          <h1>{module?.title}</h1>
        </div>
        {isFormChanged && (
          <p>{isLoading ? 'Enregistrement en cours...' : 'Formulaire enregistré'}</p>
        )}
      </div>
    );
  };

  const renderQuestion = (key, question, prefix_id = '', form) => {
    // const { form } = this.state

    switch (question.type) {
      case 'text': {
        return (
          <QuizTextArea
            key={key}
            label={`${question.title}${question.options.required != null && question.options.required ? ' *' : ''
              }`}
            stepLabel={(key + 1).toString()}
            stepOutline={prefix_id !== ''}
            name={`${prefix_id}${question.id}`}
            value={question.answer}
            isQuestion={true}
            onChange={() => { }}
            placeholder={'Entrez votre réponse ici...'}
            // maxLength={
            //   question.options.max_length != null && question.options.max_length
            //     ? parseInt(question.options.max_length, 10)
            //     : 0
            // }
            disabled={true}
          />
        );
      }
      case 'select': {
        const tmp = JSON.parse(question?.options).choices;
        const choices = tmp;
        const comboFieldProps = {
          name: `${prefix_id}${question.id}`,
          options: choices,
          onChange: () => { },
          value: question.answer,
          placeholder: 'Sélectionnez une réponse',
          clearable: question.options.required == null || !question.options.required,
          readOnly: form !== null && form.done,
        };

        return (
          <QuizCombo
            key={key}
            label={`${question.title}${question.options.required != null && question.options.required ? ' *' : ''
              }`}
            stepLabel={key.toString()}
            stepOutline={prefix_id !== ''}
            comboFieldProps={comboFieldProps}
          />
        );
      }
      case 'multiple_choice': {
        const prefix_question = `${prefix_id}${question.id}`;
        const tmp = JSON.parse(question?.options).choices;
        const choices = JSON.parse(tmp);

        // if (question.options.allow_other != null && question.options.allow_other) {
        //   const isSelected =
        //     this.state.results &&
        //     this.state.results[prefix_question] &&
        //     this.state.results[prefix_question]["Question à choix multiples"]
        //   choices.push({
        //     label:"Question à choix multiples",
        //     isSelected: isSelected
        //       ? this.state.results[prefix_question]["Question à choix multiples"]
        //       : false,
        //   })
        // }

        const handleMultipleChoiceChange = (value) => {
          const newState = choices.reduce(
            (res, choice) => ({
              ...res,
              [choice.label]: value.id === choice.label ? value.value : choice.isSelected,
            }),
            {},
          );

          this.handleAnswer({ id: `${prefix_id}${question.id}`, value: newState });
        };
        return (
          <QuizQcm
            key={key}
            label={`${question.title}`}
            stepLabel={key.toString()}
            stepOutline={prefix_id !== ''}
            options={choices}
            onChange={handleMultipleChoiceChange}
            disabled={true}
          />
        );
      }
      case 'group': {
        return (
          <QuizGroup
            key={key}
            label={question.title}
            stepLabel={key.toString()}
            stepOutline={prefix_id !== ''}
          >
            {question.questions
              .sort((q1, q2) => q1.order - q2.order)
              .map((itemQuestion, key) =>
                this.renderQuestion(key + 1, itemQuestion, `${question.id}_`),
              )}
          </QuizGroup>
        );
      }
      case 'yes_no': {
        return (
          <QuizYesNo
            key={key}
            label={`${question.title}${question.options.required != null && question.options.required ? ' *' : ''
              }`}
            stepLabel={key.toString()}
            stepOutline={prefix_id !== ''}
            id={`${prefix_id}${question.id}`}
            value={question.answer === 'Oui' ? true : false}
            yesLabel={'Oui'}
            noLabel={'Non'}
            onChange={() => { }}
            disabled={true}
          />
        );
      }
      case 'rating': {
        return (
          <QuizStars
            key={key}
            id={`${prefix_id}${question.id}`}
            label={`${question.title}${question.options.required != null && question.options.required ? ' *' : ''
              }`}
            stepLabel={key.toString()}
            stepOutline={prefix_id !== ''}
            starsCount={5}
            selectedStars={question.answer ? parseInt(question.answer, 10) : 0}
            onChange={() => { }}
            disabled={true}
          />
        );
      }
      case 'opinion_scale': {
        return (
          <QuizOpinionScale
            key={key}
            id={`${prefix_id}${question.id}`}
            label={`${question.title}${question.options.required != null && question.options.required ? ' *' : ''
              }`}
            stepLabel={key.toString()}
            stepOutline={prefix_id !== ''}
            scale={question.options.scale ? question.options.scale : 10}
            value={question.answer ? parseInt(question.answer, 10) : 0}
            onChange={() => { }}
            disabled={true}
          />
        );
      }
      case 'date': {
        return (
          <QuizDate
            key={key}
            id={`${prefix_id}${question.id}`}
            label={`${question.title}${question.options.required != null && question.options.required ? ' *' : ''
              }`}
            stepLabel={key.toString()}
            stepOutline={prefix_id !== ''}
            monthLabels={[
              'Janvier',
              'Février',
              'Mars',
              'Avril',
              'Mai',
              'Juin',
              'Juillet',
              'Août',
              'Septembre',
              'Octobre',
              'Novembre',
              'Décembre',
            ]}
            dayTitle={'Jour'}
            monthTitle={'Mois'}
            yearTitle={'Année'}
            value={question.answer}
            onChange={() => { }}
            disabled={true}
          />
        );
      }
      default: {
        return null;
      }
    }
  };

  const renderQuestions = () => {
    return (
      <div className="form-edit-page-container">
        <h3 className='title-ressource-view-quiz title'>Formulaire : {module?.title}</h3>

        {questions.map((question, i) => (
          <div key={question.id}>
            {/* <QuizStep label={question.title} stepLabel={i + 1} stepOutline={false} /> */}
            {renderQuestion(i, question, '', question)}
          </div>
        ))}
      </div>
    );
  };

  const printCourse = () => {
    window.print();
  };

  const renderContent = () => {
    return (
      <>
        {renderHeader()}

        <div className="form-edit-page-content print-container">
          <div className="form-edit-page-actions">
            {(
              <PrimaryButton
                icon={'download'}
                label={'Exporter au format PDF'}
                onClick={printCourse}
              />
              // <button className='export-button' onClick={printCourse}><Icon icon="download" color={'accent'} /></button>
            )}
          </div>
          {/* <ExportContainerTinyMCE> */}
          <div>{renderQuestions()}</div>
          {/* </ExportContainerTinyMCE> */}
        </div>
      </>
    );
  };

  return (
    <div className="form-edit-page">
      <RightAsideLayout aside={null} content={renderContent()} />
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Page(ConsultantCourseFormViewPage));
