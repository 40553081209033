import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _pick from 'lodash/pick'
import _mapValues from 'lodash/mapValues'
import moment from 'moment/moment'
import 'moment/locale/fr'

import { reduxForm } from 'redux-form'

import { BeneficiaryProfileContent } from '../BeneficiaryProfileContent/BeneficiaryProfileContent'
import { asyncValidate } from '../../../helpers/form/FormValidator/FormValidator'
import { getValidationRules, profileFields } from '../../../helpers/beneficiaryProfileForm'

const mapStateToProps = (props) => ({
  initialValues: {
    ...props.user,
    ..._mapValues(_pick(props.user, ['birth_date']), (date) => {
      if (date !== null && date !== '') return moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY')
      return date
    }),
  },
})

class BeneficiaryProfileForm extends PureComponent {
  static propTypes = {
    user: PropTypes.any,
  }

  render() {
    const { user } = this.props

    if (!user) {
      return null
    }

    return (
      <form noValidate>
        <BeneficiaryProfileContent />
      </form>
    )
  }
}

BeneficiaryProfileForm = reduxForm({
  form: 'beneficiary-edit',
  asyncChangeFields: profileFields,
  asyncValidate: (values, dispatch, props) => {
    const validationRules = getValidationRules(props.user, values)
    return asyncValidate(values, validationRules)
  },
})(BeneficiaryProfileForm)

export default connect(mapStateToProps, null)(BeneficiaryProfileForm)