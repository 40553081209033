/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { selectors as UserSelectors } from '../../../redux/UserRedux';
import { connect } from 'react-redux';

import { getConsultantInfos } from '../../../api/ConsultantApi';
import {
  updateAppointment,
  deleteParticipantAppointment,
  relaunchInvitationAppointmentAllParticipants,
  relaunchInvitationAppointmentOneParticipant,
  updateStatusGuestAppointment,
  getAppointmentById,
} from '../../../api/AppointmentsApi';

import { BackgroundIcon } from '../../atoms/BackgroundIcon/BackgroundIcon';
import EditAppointmentPopin from '../../../containers/popins/appointment/EditAppointmentPopin/EditAppointmentPopin';
import Icon from '../../atoms/Icon/Icon';
import { NewTag } from '../../atoms/NewTag/NewTag';
import PrimaryButton from '../../atoms/PrimaryButton/PrimaryButton';
import { ReportAppointmentPopin } from '../../../containers/popins/appointment/ReportAppointmentPopin/ReportAppointmentPopin';
import { createVisioRoom } from '../../../api/TwilioApi';
import { useReactToPrint } from 'react-to-print';
import moment from 'moment';
import 'moment/locale/fr';
import { sendTallysheetAppointmentAllParticipants } from '../../../api/AppointmentsApi';
import './AgendaSidebar.scss';

import { selectors as OfficeSelectors } from '../../../redux/OfficeRedux';

const mapStateToProps = (state) => ({
  user: UserSelectors.user(state),
});

const PrintableContent = ({ contentRef, appointment, user }) => {
  const office = useSelector(OfficeSelectors.office);
  return (
    <div ref={contentRef}>
      {/* Votre contenu à convertir en PDF */}
      <div className="agenda-tallysheet-pdf" style={{ padding: '20px' }}>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img
            className="office-logo"
            style={{ width: '20%' }}
            src={`${process.env.REACT_APP_ASSETS_API_STATIC_URL}${office?.image_url?.replace(
              process?.env?.REACT_APP_OLD_UPLOADS_API_STATIC_URL,
              '',
            )}`}
            alt={office?.name}
          />
        </div>
        <h4 style={{ paddingTop: '20px' }}>Feuille d'émargement</h4>
        <p>
          Rendez-vous {appointment.appointment_type === 'Individual' ? 'individuel' : 'collectif'} :{' '}
          {appointment.title}
        </p>
        <p>
          Rendez-vous du {moment(appointment.rdv_at, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY')} à{' '}
          {moment(appointment.rdv_at, 'YYYY-MM-DD HH:mm:ss').format('HH:mm')}
        </p>
        <p>
          {appointment.videocall_type === 'none'
            ? 'Rendez-vous presentiel'
            : 'Rendez-vous distanciel : ' + appointment.videocall_url}
        </p>
        <p>
          Organisé par : {user.first_name} {user.last_name} #{user.id}
        </p>
        <div style={{ backgroundColor: 'black', height: '1px', width: '100%' }}></div>
        <table style={{ width: '100%' }}>
          <thead>
            <tr>
              <th>Nom</th>
              <th>Prénom</th>
              <th>Email</th>
              <th>État</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{user.first_name}</td>
              <td>{user.last_name}</td>
              <td>{user.email}</td>
              <td>
                {appointment.user_from_tallysheet_is_signed
                  ? 'Signé électroniquement le ' +
                  moment(
                    appointment.user_from_tallysheet_date_signed,
                    'YYYY-MM-DD HH:mm:ss',
                  ).format('DD/MM/YYYY') +
                  ' à ' +
                  moment(
                    appointment.user_from_tallysheet_date_signed,
                    'YYYY-MM-DD HH:mm:ss',
                  ).format('HH:mm')
                  : ''}
              </td>
            </tr>
            {appointment.users_to.individuals.map((participant) => (
              <tr>
                <td>{participant.first_name}</td>
                <td>{participant.last_name}</td>
                <td>{participant.email}</td>
                <td>
                  {participant.tallysheet_is_signed
                    ? 'Signé électroniquement le ' +
                    moment(participant.tallysheet_date_signed, 'YYYY-MM-DD HH:mm:ss').format(
                      'DD/MM/YYYY',
                    ) +
                    ' à ' +
                    moment(participant.tallysheet_date_signed, 'YYYY-MM-DD HH:mm:ss').format(
                      'HH:mm',
                    )
                    : ''}
                </td>
              </tr>
            ))}
            {appointment.users_to.groups.map((group) =>
              group.group_users.map((participant) => (
                <tr>
                  <td>{participant.first_name}</td>
                  <td>{participant.last_name}</td>
                  <td>{participant.email}</td>
                  <td>
                    {participant.tallysheet_is_signed
                      ? 'Signé électroniquement le ' +
                      moment(participant.tallysheet_date_signed, 'YYYY-MM-DD HH:mm:ss').format(
                        'DD/MM/YYYY',
                      ) +
                      ' à ' +
                      moment(participant.tallysheet_date_signed, 'YYYY-MM-DD HH:mm:ss').format(
                        'HH:mm',
                      )
                      : ''}
                  </td>
                </tr>
              )),
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export const AgendaSidebar = ({
  appointmentProps,
  onClickBack,
  setDeleteAppointment,
  setEditAppointment,
  user,
  setDeclineAppointment,
  setActionsConfirmationAppointment,
  setToaster,
}) => {
  const [appointment, setAppointment] = useState();
  const isBeneficiary = useSelector(UserSelectors.isBeneficiary);
  const [isConsultantAppointmentOwner, setIsConsultantAppointmentOwner] = useState();
  const [showEditAppointmentModal, setShowEditAppointmentModal] = useState(false);
  const [isOpenModalReportAppointment, setIsOpenModalReportAppointment] = useState(false);
  const [participants, setParticipants] = useState({ individuals: [], groups: [] });
  const [consultant, setConsultant] = useState({
    id: '',
    first_name: '',
    last_name: '',
    image_url: '',
  });
  const contentPrintRef = useRef();

  // METHODS
  const getDeleteAppointment = (appointment) => {
    setDeleteAppointment(appointment);
  };

  const getEditAppointment = (appointment) => {
    setEditAppointment(appointment);
    setShowEditAppointmentModal(false);
  };

  const handleDeleteParticipantOnClick = (participant) => {
    const filterParticipantsIndividuals = participants.individuals.filter(
      (p) => p.user_id !== participant.user_id,
    );
    const filterParticipantsGroups = participants.groups.map((group) => {
      const updatedGroupUsers = group.group_users.filter(
        (user) => user.user_id !== participant.user_id,
      );
      return { ...group, group_users: updatedGroupUsers };
    });

    deleteParticipantAppointment(appointment.id, participant.user_id);
    setParticipants({
      individuals: filterParticipantsIndividuals,
      groups: filterParticipantsGroups,
    });
    setAppointment({
      ...appointment,
      users_to: { individuals: filterParticipantsIndividuals, groups: filterParticipantsGroups },
    });
  };

  const handleActionsConfirmationOnClick = (status) => {
    status === 'ko' && setDeclineAppointment({ appointment: appointment, status: status });
    status === 'ok' &&
      setActionsConfirmationAppointment({ appointment: appointment, status: status });
  };

  const handleOnCloseDeleteModal = (isOpen) => {
    setShowEditAppointmentModal(isOpen);
  };

  const handleReportAppointment = (comment) => {
    const report_appointment = { status: 'rescheduled', comment: comment };

    updateStatusGuestAppointment(appointment.id, user.id, report_appointment)
      .then((response) => {
        setToaster({ title: 'Rendez-vous reporté', message: '', type: 'success' });
        setEditAppointment(response.appointment);
      })
      .catch(() => {
        setToaster({
          title: 'Erreur lors du report du rendez-vous',
          message: 'Une erreur est survenue lors du report du rendez-vous',
          type: 'success',
        });
      });

    setIsOpenModalReportAppointment(false);

    const user_in_individual = appointment.users_to.individuals.find(
      (participant) => participant.user_id === user.id,
    );
    if (user_in_individual) {
      user_in_individual.status = 'rescheduled';
    }

    appointment.users_to.groups.forEach((group) => {
      const user_in_group = group.group_users.find(
        (participant) => participant.user_id === user.id,
      );
      if (user_in_group) {
        user_in_group.status = 'rescheduled';
      }
    });
  };

  const handleResendInvitationAppointmentOneParticipant = (participant) => {
    relaunchInvitationAppointmentOneParticipant(appointment.id, participant.user_id)
      .then(() => {
        setToaster({
          title: 'Invitation renvoyée',
          message: "L'invitation du rendez-vous vient d'être renvoyée par email au talent.",
          type: 'success',
        });
      })
      .catch(() => {
        setToaster({
          title: "Erreur d'envoi d'invitation",
          message: "Une erreur est survenue lors de l'envoi d'invitation au rendez-vous",
          type: 'error',
        });
      });
  };

  const handleResendInvitationAppointmentAllParticipants = () => {
    relaunchInvitationAppointmentAllParticipants(appointment.id)
      .then(() => {
        setToaster({
          title: 'Invitations envoyées',
          message:
            "Les invitations viennent d'être envoyées à l'ensemble des participants du rendez-vous.",
          type: 'success',
        });
      })
      .catch(() => {
        setToaster({
          title: "Erreur d'envoi d'invitations",
          message: "Une erreur est survenue lors de l'envoi des invitations au rendez-vous",
          type: 'error',
        });
      });
  };

  const handleSendTallysheetOnClick = () => {
    sendTallysheetAppointmentAllParticipants(appointment.id)
      .then(() => {
        setToaster({
          title: 'Emargement envoyé',
          message: "Le mail pour l'emargement à bien été envoyé à tous les participants",
          type: 'success',
        });
      })
      .catch(() => {
        setToaster({
          title: 'Emargement non envoyé',
          message: "Une erreur est survenue lors de l'envoi de la feuille d'émargement",
          type: 'error',
        });
      });
  };

  const handleGenerateAndJoinVisioRoom = (e) => {
    if (appointment.videocall_type === "default" && !appointment.videocall_url) {
      getAppointmentById(appointment.id).then((response) => {
        window.open(response.appointment.videocall_url, '_blank');
      })
    }

    else {
      if (appointment.videocall_type === 'twilio') {
        createVisioRoom(appointment.id).then((res) => {
          //nothing, we just create the room
        });
      }

      window.open(appointment.videocall_url, '_blank');
    }
  };

  // USE EFFECTS
  useEffect(() => {
    setAppointment(appointmentProps);
  }, [appointmentProps]);

  useEffect(() => {
    if (appointment) {
      setParticipants({
        individuals: appointment.users_to.individuals,
        groups: appointment.users_to.groups,
      });

      getConsultantInfos(appointment.user_from).then((response) => {
        const consultant = response.consultant.user;
        setConsultant({
          id: consultant.id,
          first_name: consultant.first_name,
          last_name: consultant.last_name,
          image_url: consultant.image_url,
        });
      });
      const isOwner = appointment.user_from === user.id;
      setIsConsultantAppointmentOwner(isOwner);
    }
  }, [appointment, user]);

  // RENDER COMPONENTS
  const renderHeader = () => {
    return (
      <div className="agenda-side-bar-header">
        <div className="icon-back" onClick={() => onClickBack(true)}>
          <Icon icon="back" color={'grey1'} />
          <p>Retour</p>
        </div>

        <div className="agenda-side-bar-infos">
          <NewTag
            title={
              appointment.appointment_type === 'Individual'
                ? 'Rendez-vous individuel'
                : 'Atelier collectif'
            }
            color={'main-color'}
          />
          {!isBeneficiary && isConsultantAppointmentOwner && (
            <div className="agenda-side-bar-actions">
              <div onClick={() => setShowEditAppointmentModal(true)}>
                <Icon icon="pencil" />
              </div>
            </div>
          )}
        </div>
        <h1>{appointment.title}</h1>
      </div>
    );
  };

  const renderVideoCall = (appointment) => {
    if (appointment.is_videocall !== 1) {
      return null;
    }

    const renderVideoCallInfo = (appointment) => {
      switch (appointment.videocall_type) {
        case 'tel':
          return (
            <>
              <BackgroundIcon icon={'phone'} color={'main-color'} />
              {appointment.phone ? (
                <a target="_blank" href={'tel:' + appointment.phone}>
                  Appeler
                </a>
              ) : (
                <p style={{ color: 'grey', textDecoration: 'none' }}>Aucun numéro enregistré</p>
              )}
            </>
          );
        case 'default':
          return (
            <>
              <BackgroundIcon icon={'visio'} color={'main-color'} />
              <p onClick={(e) => handleGenerateAndJoinVisioRoom()}>Rejoindre la réunion</p>
            </>
          );
        default:
          if (appointment.videocall_url) {
            return (
              <>
                <BackgroundIcon icon={'visio'} color={'main-color'} />
                <p onClick={(e) => handleGenerateAndJoinVisioRoom()}>Rejoindre la réunion</p>
              </>
            );
          }
          break;
      }
      return null;
    }

    return (
      <div className="agenda-side-bar-main-info">
        {renderVideoCallInfo(appointment)}
      </div>
    )
  }

  const renderInfos = () => {
    return (
      <div className="agenda-side-bar-main-infos">
        <div className="agenda-side-bar-main-info">
          <BackgroundIcon icon={'calendar'} color={'main-color'} />
          <p className="infos-bold">{moment(appointment.rdv_at).format('LL')}</p>
        </div>
        <div className="agenda-side-bar-main-info">
          <BackgroundIcon icon={'clock'} color={'main-color'} />
          <p className="infos-bold">
            {moment(appointment.rdv_at).format('HH:mm')} -{' '}
            {moment(appointment.rdv_to).format('HH:mm')}
          </p>
        </div>
        {appointment.appointment_type === 'Collective' && (
          <div className="agenda-side-bar-main-info">
            <BackgroundIcon icon={'person'} color={'main-color'} />
            <p className="infos-bold">
              {appointment.nb_seats} {appointment.nb_seats > 1 ? 'places' : 'place'}{' '}
            </p>
          </div>
        )}
        {renderVideoCall(appointment)}

        {appointment.address && (
          <div className="agenda-side-bar-main-info">
            <BackgroundIcon icon={'pin'} color={'main-color'} />
            <p>{appointment.address}</p>
          </div>
        )}
      </div>
    );
  };

  const renderConsultant = () => {
    return (
      <div className="agenda-side-bar-consultant">
        <label>CONSULTANT</label>
        <div className="agenda-side-bar-consultant-info">
          <div className="agenda-side-bar-consultant-name">
            {/* <img alt="profile-conusltant" className="profile-picture" src="" /> */}
            {consultant.image_url ? (
              <img
                alt="profile-conusltant"
                className="profile-picture"
                src={`${process.env.REACT_APP_ASSETS_API_STATIC_URL}${consultant.image_url.replace(
                  process.env.REACT_APP_OLD_UPLOADS_API_STATIC_URL,
                  '',
                )}`}
              />
            ) : (
              <div class="avatar-circle">
                <div class="no-picture">
                  <div class="icon person" data-color="accent">
                    <svg
                      width="13"
                      height="13"
                      viewBox="0 0 13 13"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <title>user</title>
                      <g
                        transform="translate(1 1)"
                        stroke="#5066EF"
                        stroke-width="1.5"
                        fill="none"
                        fill-rule="evenodd"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path d="M11 11V9.667C11 8.194 9.769 7 8.25 7h-5.5C1.231 7 0 8.194 0 9.667V11"></path>
                        <circle cx="5.5" cy="2.5" r="2.5"></circle>
                      </g>
                    </svg>
                  </div>
                </div>
              </div>
            )}
            <p>
              {consultant.first_name} {consultant.last_name}
            </p>
          </div>
        </div>
      </div>
    );
  };

  const renderTagStatus = (status) => {
    return (
      <>
        {status === 'ok' && <NewTag title={'Confirmé'} color={'green'} />}
        {status === 'pending' && <NewTag title={'En attente'} color={'grey'} />}
        {status === 'ko' && <NewTag title={'Refusé'} color={'red'} />}
        {status === 'rescheduled' && <NewTag title={'Reporté'} color={'orange'} />}
      </>
    );
  };

  const renderParticipants = () => {
    const totalGroupUsers =
      participants.groups?.reduce((sum, group) => sum + group?.group_users?.length, 0) || 0;
    const totalIndividuals = participants.individuals?.length || 0;
    const totalParticipants = totalIndividuals + totalGroupUsers;

    const { is_tallysheet, rdv_at } = appointment;
    const isTallysheet = is_tallysheet === 1;
    const date = moment(rdv_at).format('YYYY/MM/DD');
    const today = moment();
    const isToday = moment(date).isSame(today, 'day');

    return (
      <div className="agenda-side-bar-participants">
        <div className="agenda-side-bar-participants-title">
          <label>{totalParticipants > 1 ? 'INVITATIONS' : 'INVITATION'}</label>
          {appointment.appointment_type === 'Collective' && (
            <span className="agenda-side-bar-total-participants">
              <p>{totalParticipants}</p>
            </span>
          )}
        </div>
        <div className="agenda-side-bar-all-participants">
          {participants.individuals &&
            participants.individuals.map((participant, index) => (
              <div className="agenda-side-bar-participant" key={index}>
                <div className="agenda-side-bar-participant-name">
                  {participant.image_url ? (
                    <img
                      alt="profile-talent"
                      className="profile-picture"
                      src={`${process.env.REACT_APP_ASSETS_API_STATIC_URL
                        }${participant.image_url.replace(
                          process.env.REACT_APP_OLD_UPLOADS_API_STATIC_URL,
                          '',
                        )}`}
                    />
                  ) : (
                    <div class="avatar-circle">
                      <div class="no-picture">
                        <div class="icon person" data-color="accent">
                          <svg
                            width="13"
                            height="13"
                            viewBox="0 0 13 13"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <title>user</title>
                            <g
                              transform="translate(1 1)"
                              stroke="#5066EF"
                              stroke-width="1.5"
                              fill="none"
                              fill-rule="evenodd"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            >
                              <path d="M11 11V9.667C11 8.194 9.769 7 8.25 7h-5.5C1.231 7 0 8.194 0 9.667V11"></path>
                              <circle cx="5.5" cy="2.5" r="2.5"></circle>
                            </g>
                          </svg>
                        </div>
                      </div>
                    </div>
                  )}
                  <p>
                    {participant.first_name} {participant.last_name}
                  </p>
                </div>

                <div className="agenda-side-bar-participant-actions">
                  {renderTagStatus(participant.status)}
                  {/* {(isConsultantAppointmentOwner &&
                    participant.status === 'ok' &&
                    isTallysheet && !participant.tallysheet_is_signed &&
                    isToday) && <NewTag title={'À faire signer'} color={'orange'} />} */}
                  {isConsultantAppointmentOwner &&
                    participant.status === 'ok' &&
                    isTallysheet &&
                    participant.tallysheet_is_signed &&
                    isToday ? (
                    <NewTag title={'Émargé'} color={'green'} />
                  ) : (
                    ''
                  )}
                  {isConsultantAppointmentOwner && (
                    <button
                      className="send-invitation-button"
                      onClick={() => handleResendInvitationAppointmentOneParticipant(participant)}
                    >
                      Relancer
                    </button>
                  )}

                  {!isBeneficiary && isConsultantAppointmentOwner && (
                    <div onClick={() => handleDeleteParticipantOnClick(participant)}>
                      <Icon icon="trash2" />
                    </div>
                  )}
                </div>
              </div>
            ))}

          {participants.groups &&
            participants.groups.map((group) =>
              group.group_users.map((participant, index) => (
                <div className="agenda-side-bar-participant" key={index}>
                  <div className="agenda-side-bar-participant-name">
                    {participant.image_url ? (
                      <img
                        alt="profile-talent"
                        className="profile-picture"
                        src={`${process.env.REACT_APP_ASSETS_API_STATIC_URL
                          }${participant.image_url.replace(
                            process.env.REACT_APP_OLD_UPLOADS_API_STATIC_URL,
                            '',
                          )}`}
                      />
                    ) : (
                      <div class="avatar-circle">
                        <div class="no-picture">
                          <div class="icon person" data-color="accent">
                            <svg
                              width="13"
                              height="13"
                              viewBox="0 0 13 13"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <title>user</title>
                              <g
                                transform="translate(1 1)"
                                stroke="#5066EF"
                                stroke-width="1.5"
                                fill="none"
                                fill-rule="evenodd"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              >
                                <path d="M11 11V9.667C11 8.194 9.769 7 8.25 7h-5.5C1.231 7 0 8.194 0 9.667V11"></path>
                                <circle cx="5.5" cy="2.5" r="2.5"></circle>
                              </g>
                            </svg>
                          </div>
                        </div>
                      </div>
                    )}
                    <p>
                      {participant.first_name} {participant.last_name}
                    </p>
                  </div>

                  <div className="agenda-side-bar-participant-actions">
                    {renderTagStatus(participant.status)}
                    {isConsultantAppointmentOwner &&
                      participant.status === 'ok' &&
                      isTallysheet &&
                      !participant.tallysheet_is_signed &&
                      isToday && <NewTag title={'À faire signer'} color={'orange'} />}
                    {isConsultantAppointmentOwner &&
                      participant.status === 'ok' &&
                      isTallysheet &&
                      participant.tallysheet_is_signed &&
                      isToday && <NewTag title={'À signer'} color={'green'} />}
                    {isConsultantAppointmentOwner && (
                      <button
                        className="send-invitation-button"
                        onClick={() => handleResendInvitationAppointmentOneParticipant(participant)}
                      >
                        Relancer
                      </button>
                    )}

                    {!isBeneficiary && isConsultantAppointmentOwner && (
                      <div onClick={() => handleDeleteParticipantOnClick(participant)}>
                        <Icon icon="trash2" />
                      </div>
                    )}
                  </div>
                </div>
              )),
            )}
        </div>
      </div>
    );
  };

  const renderDescription = () => {
    return (
      <div className="agenda-side-bar-description">
        <label>DESCRIPTION</label>
        <p>{appointment.public_notes}</p>
      </div>
    );
  };

  const handleChangePrivateNote = (e) => {
    const value = e.target.value;
    setAppointment({ ...appointment, private_notes: value });
  };
  const changeAppointmentPrivateNote = async () => {
    await updateAppointment({
      ...appointment,
      private_notes: appointment.private_notes,
    }).then(() => {
      setToaster({
        title: 'Rendez-vous',
        message: 'Les notes privés du rendez-vous ont bien été modifié',
        type: 'success',
      });
    });
  };

  const renderPrivateNote = () => {
    return (
      <div className="agenda-side-bar-description">
        <label>NOTE PRIVEE</label>
        {/* <p>{appointment.private_notes}</p>  */}
        <textarea
          style={{ border: '#e1e1e1 1px solid', width: '100%', borderRadius: '5px' }}
          onBlur={() => changeAppointmentPrivateNote()}
          onChange={handleChangePrivateNote}
          value={appointment.private_notes}
        />
      </div>
    );
  };

  const handleConsultantSignTallysheet = () => {
    updateAppointment({
      ...appointment,
      user_from_tallysheet_is_signed: 1,
      user_from_tallysheet_date_signed: moment().format('YYYY-MM-DD HH:mm:ss'),
    }).then(() => {
      setAppointment({
        ...appointment,
        user_from_tallysheet_is_signed: 1,
        user_from_tallysheet_date_signed: moment().format('YYYY-MM-DD HH:mm:ss'),
      });
      setToaster({
        title: "Feuille d'émargement signé",
        message: "Vous avez bien signé la feuille d'émargement",
        type: 'success',
      });
    });
  };

  const renderActions = () => {
    const { appointment_type, rdv_at, status } = appointment;
    const isIndividualAppointment = appointment_type === 'Individual';

    const date = moment(rdv_at).format('YYYY/MM/DD');
    const today = moment();
    const isToday = moment(date).isSameOrBefore(today, 'day');

    return (
      <div className="agenda-side-bar-actions-appointment">
        {isConsultantAppointmentOwner ? (
          <>
            {!isIndividualAppointment && (
              <PrimaryButton
                id={'send-invitation'}
                label={"Renvoyer l'invitation à tous les participants"}
                onClick={handleResendInvitationAppointmentAllParticipants}
                nowrap={false}
              />
            )}
            {status === 'pending' && (
              <div className="warning-message">
                <span className="tooltip">
                  {' '}
                  <Icon icon="help" color="red" />
                  <span className="tooltip-text">
                    {' '}
                    <Icon icon="warning" /> Attention vos participants doivent confirmer leur
                    présence pour que l'émargement soit possible.
                  </span>
                </span>
              </div>
            )}
            {
              <PrimaryButton
                id={'tallysheet'}
                label={"Envoyer l'émargement à tous les participants"}
                disabled={!isToday || status === 'pending'}
                onClick={handleSendTallysheetOnClick}
                nowrap={false}
                badge={'Beta'}
              />
            }
            {appointment.is_tallysheet && (
              <PrimaryButton
                label={"Télécharger le justificatif d'émargement"}
                onClick={() => handlePrint()}
                cancel={true}
                id={'tallysheet'}
                nowrap={false}
                badge={'Beta'}
              />
            )}
            {!appointment.user_from_tallysheet_is_signed && (
              <PrimaryButton
                label={"Signer le justificatif d'émargement"}
                onClick={handleConsultantSignTallysheet}
                cancel={true}
                id={'tallysheet'}
                nowrap={false}
                badge={'Beta'}
              />
            )}
          </>
        ) : (
          <>
            {isIndividualAppointment && (
              <PrimaryButton
                id={'report'}
                label={'Demander à reporter le rendez-vous'}
                outline
                onClick={() => setIsOpenModalReportAppointment(true)}
              />
            )}
            <div className="cancel-appointment-button">
              <PrimaryButton
                id={'cancel'}
                label={'Annuler le rendez-vous'}
                onClick={() => handleActionsConfirmationOnClick('ko')}
              />
            </div>
          </>
        )}
      </div>
    );
  };

  const handlePrint = useReactToPrint({
    contentRef: contentPrintRef,
  });

  if (appointment) {
    return (
      <div className="agenda-side-bar">
        {renderHeader()}
        {renderInfos()}
        {renderConsultant()}
        {renderParticipants()}
        {appointment.public_notes && renderDescription()}
        {!isBeneficiary && renderPrivateNote()}
        {renderActions()}
        {showEditAppointmentModal && (
          <EditAppointmentPopin
            open={showEditAppointmentModal}
            onClose={() => setShowEditAppointmentModal(false)}
            appointment={appointment}
            setEditAppointment={getEditAppointment}
            setDeletedAppointment={getDeleteAppointment}
            onCloseDeleteModal={handleOnCloseDeleteModal}
            setToaster={setToaster}
            isModule={false}
            module={null}
          />
        )}
        {isOpenModalReportAppointment && (
          <ReportAppointmentPopin
            open={isOpenModalReportAppointment}
            onClose={() => setIsOpenModalReportAppointment(false)}
            handleReportAppointment={handleReportAppointment}
          />
        )}

        {appointment.is_tallysheet && (
          <div style={{ display: 'none' }}>
            <PrintableContent
              contentRef={contentPrintRef}
              appointment={appointment}
              user={user}
            ></PrintableContent>
          </div>
        )}
      </div>
    );
  } else return null;
};

export default connect(mapStateToProps, null)(AgendaSidebar);
