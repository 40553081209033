import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './TextAreaField.scss'
export default class TextAreaField extends Component {
  static propTypes = {
    name: PropTypes.string,
    title: PropTypes.string,
    value: PropTypes.string,
    required: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    placeholder: PropTypes.string,
    maxLength: PropTypes.number,
    disabled: PropTypes.bool,
    rows: PropTypes.number,
  }

  static defaultProps = {
    name: '',
    title: null,
    value: '',
    placeholder: '',
    required: false,
    disabled: false,
    rows: null,
  }

  handleChange = (e) => {
    this.props.onChange({ id: this.props.name, value: e.currentTarget.value })
  }

  render() {
    const { value, title, name, placeholder, maxLength, required, disabled, rows } = this.props
    const { onBlur, onFocus } = this.props

    let extraProps = {}
    if (disabled) {
      extraProps = {
        ...extraProps,
        disabled: 'disabled',
      }
    }

    if (rows !== null) {
      extraProps = {
        ...extraProps,
        rows,
      }
    }

    return (
      <div className="text-area-field" style={{height: `100%`}}>
        {title && (
          <div className="common-form-label">
            {title}
            {required ? '*' : ''}
          </div>
        )}

        <div className="textarea-container">
          {disabled ? <div className='content'>{value}</div> : <textarea
          // style={{ height: `auto`, overflowY: `visible` }}
          rows={'auto'}
            className="content"
            placeholder={placeholder}
            value={value}
            // maxLength={maxLength}
            required={required}
            onChange={this.handleChange}
            onBlur={onBlur}
            onFocus={onFocus}
            name={name}
            {...extraProps}
          />}
          
          {maxLength && (
            <div className="textarea-counter">
              {value.length}/{maxLength}
            </div>
          )}
        </div>
      </div>
    )
  }
}
