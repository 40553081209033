import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_MY_TALENTS_EVOLUTION,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

export const getListAISynthesisByBeneficiary = async (beneficiary_id) => {
  const response = await axiosInstance.get(`/aisynthesis/beneficiary/${beneficiary_id}`);
  return response.data;
};

export const getDirectives = async () => {
  const response = await axiosInstance.get('/aisynthesis/directives');
  return response.data;
};

export const getSynthesisById = async (synthesis_id) => {
  const response = await axiosInstance.get(`/aisynthesis/${synthesis_id}`);
  return response.data;
};

export const getAttachmentByBeneficiaryId = async (beneficiary_id) => {
  const response = await axiosInstance.get(
    `/aisynthesis/beneficiary/${beneficiary_id}/attachments`,
  );
  return response.data;
};

export const getRomeCode = async () => {
  const response = await axiosInstance.get('/rome_metier/list');
  return response.data;
};

export const getRomeCodeBySearch = async (body) => {
  const response = await axiosInstance.post('/rome_metier/search', body);
  return response.data;
};

export const addSynthesis = async (body) => {
  const response = await axiosInstance.post('/aisynthesis/add', body);
  return response.data;
};

export const editSynthesis = async (synthesis_id, body) => {
  const response = await axiosInstance.put(`/aisynthesis/${synthesis_id}`, body);
  return response.data;
};

export const editSection = async (synthesis_id, section_id, body) => {
  const response = await axiosInstance.put(
    `/aisynthesis/${synthesis_id}/section/${section_id}`,
    body,
  );
  return response.data;
};

export const deleteSynthesis = async (synthesis_id) => {
  const response = await axiosInstance.delete(`/aisynthesis/${synthesis_id}`);
  return response.data;
};