import React, { useState } from "react";
import { connect } from "react-redux";

import Icon from "../atoms/Icon/Icon";
import IconButton from "../atoms/IconButton/IconButton";
import { selectors as UserSelectors } from '../../redux/UserRedux';
import { createVisioRoom } from "../../api/TwilioApi";
import moment from "moment";

const mapStateToProps = (state) => ({
    globalUser: UserSelectors.user(state)
})

const ActiveConversation = ({ globalUser, activeConversation, activeConversationName, handleSendMessage }) => {
    const displayVisio = globalUser.role === "consultant" || globalUser.role === "manager" || globalUser.role === "admin";
    const [visioDisabled, setVisioDisabled] = useState(false);

    const handleVideoCallInviteClick = (room_key) => {
        setVisioDisabled(true);

        createVisioRoom(room_key);

        const join_url = process.env.REACT_APP_VISIO_FRONT_URL + room_key

        const message = {
            random_key: (Math.random() * (40000 - 20000) + 20000) + "_" + (Math.random() * (20000 - 10000) + 10000),
            id_room: activeConversation.id,
            room_key: room_key,
            id_author: activeConversation.user_id,
            id_receiver: activeConversation.acquaintance_id,
            content: "Rejoignez-moi en visio grâce au lien suivant : " + join_url,
            date_emitted: moment().format('DD/MM/YYYY HH:mm:ss'),
            type: "visio",
            author_name: activeConversation.user_name
        };

        handleSendMessage(message);

        setTimeout(() => {
            setVisioDisabled(false);
        }, 5000);
    }

    if (!activeConversation) {
        return null
    }

    return (
        <div className="container-active-conversation">
            <div style={{ display: "flex" }}>
                <img src={!activeConversation.profile_picture ? "../static/icons/person-missing.svg" : `${process.env.REACT_APP_ASSETS_API_STATIC_URL}${(activeConversation.profile_picture).replace(process.env.REACT_APP_OLD_UPLOADS_API_STATIC_URL, '')}`} alt=" " />
                <p><span className="at-symbol">@</span>{activeConversationName}</p>
            </div>
            {displayVisio &&
                <div className={`visio ${visioDisabled ? "visio-disabled" : ""}`} >
                    <IconButton
                        icon={Icon.icon.Visio}
                        tooltip="Appel vidéo"
                        color={visioDisabled ? "grey1" : "accent"}
                        onClick={() => handleVideoCallInviteClick(activeConversation.room_key)}
                    />
                </div>
            }
        </div>
    )
}

export default connect(mapStateToProps)(ActiveConversation);