import React, { useEffect, useState } from 'react';
import IconRessource from '../../atoms/IconRessource/IconRessource';
import './ParcoursListRessource.scss';
import { Tag } from '../../atoms/Tag/Tag';
import { actions as NavigationActions } from '../../../redux/NavigationRedux';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';
import {
  LinkRessource,
  QuizRessource,
  DocumentToReturnRessource,
  SynthesisRessource,
  AppointmentRessource,
  FeedbackRessource,
} from './Ressources/ressources';

import { Toaster } from '../../atoms/Toaster/Toaster';
import { useToaster } from '../../../hooks/useToaster';
import Icon from '../../atoms/Icon/Icon';
import { getModuleContainer } from '../../../api/CourseApi';
const mapDispatchToProps = (dispatch) => ({
  redirect: (pathname) => dispatch(NavigationActions.push(pathname)),
});

const ParcoursListRessource = ({
  module,
  redirect,
  id_step,
  id_moduleContainer,
  isExport = false,
  course,
  stateModuleContainer,
  allModules
}) => {
  const [formState, setFormState] = useState();
  const location = useLocation();
  const currentURL = location.pathname;
  const { toasters, removeToaster } = useToaster();
  const [moduleContainer, setModuleContainer] = useState();
  const [state, setState] = useState(false);
  const [stateContainer, setStateContainer] = useState();

  useEffect(() => {
    getModuleContainer(id_moduleContainer).then((data) => {
      setModuleContainer(data.modules_container);
    });
  }, [id_moduleContainer]);

  // eslint-disable-next-line no-unused-vars
  let title = '';

  useEffect(() => {

    if (
      moduleContainer &&
      (module.locked === 1 && (module.done === 1 || module.done === 0) ? 0 : module.done === 0 ? 1 : module.done) === 0
    ) {
      setState(checkLockekModule());
    }
  }, [moduleContainer, allModules]);

  const checkLockekModule = () => {
    const mods = moduleContainer.modules;
    const indexModule = mods.findIndex((obj) => obj.id === module.id);
    const slicedArray = mods.slice(0, indexModule);
    if (slicedArray.length === 0 && !stateModuleContainer) {
      return true
    }
    if (!stateModuleContainer)
      return true
    const response = slicedArray.some(
      (obj) =>
        (obj.locked === 1 && (obj.done !== 3)
          ? 0
          : obj.done === 0
            ? 1
            : obj.done) === 0 && obj.done !== 3,
    );
    return response;
  };

  const renderTag = () => {
    const stateTMP =
      (module.locked === 1 && state && (module.done === 1 || module.done === 0))
        ? 0
        : module.done === 0
          ? 1
          : module.done;

    return <Tag state={stateTMP} isBackgroundColor={true} />;
  };

  const getStateForm = (stateForm) => {
    setFormState(stateForm);
  };

  const renderTagQuestions = () => {
    const stateTMP =
      (module.locked === 1 && state && (module.done === 1 || module.done === 0))
        ? 0
        : module.done === 0
          ? 1
          : module.done;
    return (
      <>
        <Tag state={stateTMP} isBackgroundColor={true} />
      </>
    );
  };

  const openRessource = (id) => {
    if (module.data.length > 0) {
      const stateTMP =
        module.locked === 1 && state && (module.done === 1 || module.done === 0)
          ? 0
          : module.done === 0
            ? 1
            : module.done;
      if (stateTMP !== 0) {
        redirect(`${currentURL}/container/${id_moduleContainer}/module/${id}`);
      }
    }
  };

  const getRessourceTitle = () => {
    switch (module.type) {
      case 'form':
        return (title = 'Quiz');
      case 'document_to_return':
        return (title = 'Document à retourner');
      case 'appointmentEvo':
        return (title = 'Rendez-vous');
      case 'synthesis':
        return (title = 'Synthèse');
      case 'feedback':
        return (title = 'Feedback');
      default:
        return null;
    }
  };

  const getRessourceTitleDocument = () => {
    if (!module.data[0]) {
      return null;
    }

    switch (module.data[0]?.type) {
      case 'file':
        return (title = 'Document');
      case 'link':
        return (title = 'Lien');
      case 'video':
        return (title = 'Vidéo');
      default:
        return null;
    }
  };

  const renderTypeResource = () => {
    switch (module.type) {
      case 'document':
        return <LinkRessource ressources={module.data} isExport={isExport} />;
      case 'form':
        return (
          <QuizRessource
            setState={getStateForm}
            ressources={module.data[0]}
            title={module.title}
            isExport={isExport}
          />
        );
      case 'document_to_return':
        return (
          <DocumentToReturnRessource
            ressource={module.data[0]}
            step={id_step}
            moduleContainer={id_moduleContainer}
          />
        );
      case 'appointmentEvo':
        return <AppointmentRessource ressource={module.data[0]} appointment={module} isExport={isExport} />;
      case 'synthesis':
        return <SynthesisRessource ressource={module.data[0]} isExport={isExport} />;
      case 'feedback':
        return <FeedbackRessource ressource={module.data[0]} isExport={isExport} />;

      default:
        return null;
    }
  };

  const renderRessource = () => {

    return (
      <>
        <div className="ressource-card-illustration">
          {module.type !== 'document' ? (
            <IconRessource icon={module.type ?? null} />
          ) : (
            <IconRessource icon={(module.data[0] && module.data[0].type) ? module.data[0].type : null} />
          )}
          {module.type !== 'document' ? (
            <p>{getRessourceTitle()}</p>
          ) : (
            <p>{getRessourceTitleDocument()}</p>
          )}
        </div>
        <div className="ressource-card-content">
          <div className="d-flex">
            {module.type !== 'form' ? renderTag() : renderTagQuestions()}
            {module.locked && !state ? (
              <div className="ms-3 d-flex position-relative" style={{ position: 'relative' }}>
                <Icon icon={Icon.icon.Lock} color={Icon.color.Grey1} />
                <p className="message-lock">
                  * Vous devez compléter le document avant de passer au module suivant
                </p>
              </div>
            ) : (
              ''
            )}
          </div>
          <hr />
          <div className="ressource-card-content-presentation">{renderTypeResource()}</div>
        </div>
      </>
    );
  };

  return (
    <div
      className={`parcours-list-resource ${module.type}`}
      id={module.id}
      onClick={() => openRessource(module.id)}
      style={{ breakBefore: 'all' }}
    >
      <div className="page-break" />

      {module.type !== 'text' && renderRessource()}
      <div className="notification-container">
        {toasters.map((toaster) => (
          <Toaster
            key={toaster.id}
            title={toaster.title}
            message={toaster.message}
            type={toaster.type}
            onClose={() => removeToaster(toaster.id)}
          />
        ))}
      </div>
    </div>
  );
};

export default connect(null, mapDispatchToProps)(ParcoursListRessource);
