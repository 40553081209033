import React, { useState } from 'react';
import PropTypes from 'prop-types';
import IconCardTitle from '../../molecules/IconCardTitle/IconCardTitle';
import InputField from '../../molecules/InputField/InputField';
import DateField from '../../molecules/DateField/DateField';
import DescriptionTextArea from '../../molecules/DescriptionTextArea/DescriptionTextArea';

import { Modal } from '../../atoms/Modal/Modal';
import CircleButton from '../../atoms/CircleButton/CircleButton';
import moment from 'moment';
import './SyntheseCoCard.scss';

export const SyntheseCoCard = ({ title, locale, readOnly = false, onChange, syntheses }) => {
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [isOpenView, setIsOpenView] = useState(false);
  const [selectedSynthese, setSelectedSynthese] = useState([]);
  const [createTitle, setCreateTitle] = useState('');
  const [createDate, setCreateDate] = useState('');
  const [createDescription, setCreateDescription] = useState('');

  const handleCreateSynthese = (title, date, description) => {
    const newVal = {
      id: '',
      user_id: '',
      title: title,
      edition_date: date,
      content: description,
    };
    onChange({
      id: 'synthesis',
      value: [...syntheses, newVal],
    });
    setCreateTitle('');
    setCreateDate('');
    setCreateDescription('');
    setIsOpenEdit(false);
  };

  const handleEditSynthese = (id, title, date, description) => {
    const newSyntheses = syntheses.map((synthese) => {
      if (synthese.id !== id) {
        return synthese
      }
      return { ...synthese, title: title, edition_date: date, content: description }
    })

    onChange({
      id: 'synthesis',
      value: newSyntheses,
    });
    setIsOpenView(false);
  };

  const {
    titleSyntheseLabel,
    dateSyntheseLabel,
    descriptionSyntheseLabel,
    monthLabels,
    dayShortLabels,
  } = locale;

  const handleDeleteSynthese = (synthese) => {
    onChange({
      id: 'synthesis',
      value: syntheses.filter(item => item.id !== synthese.id),
    });
  }

  const renderEditSynthesePopin = () => {
    return (
      <div className="accomp-collective-synthese-co-popin">
        <InputField
          title={titleSyntheseLabel}
          name={'title'}
          value={createTitle}
          onChange={(event) => {
            setCreateTitle(event.value);
          }}
          type="string"
          readonly={readOnly}
        />
        <DateField
          title={dateSyntheseLabel}
          name={'date'}
          value={createDate}
          calendarLabels={{ dayShortLabels, monthLabels }}
          onChange={(event) => {
            setCreateDate(moment(event.value, 'DD/MM/YYYY').format('YYYY-MM-DD HH:mm:ss'));
          }}
          locale={locale}
          readOnly={readOnly}
        />
        <DescriptionTextArea
          name={'description'}
          placeholder={descriptionSyntheseLabel}
          value={createDescription}
          onChange={(event) => {
            setCreateDescription(event.value);
          }}
          type="text"
          readonly={readOnly}
          maxLength={null}
        />
      </div>
    );
  };

  const renderViewSynthesePopin = () => {
    return (
      <div className="accomp-collective-synthese-co-popin">
        <InputField
          title={titleSyntheseLabel}
          name={'title'}
          value={selectedSynthese.title}
          onChange={(event) => {
            setSelectedSynthese(prevSelectedSynthese => ({ ...prevSelectedSynthese, title: event.value }))
          }}
          type="string"
          readonly={readOnly}
        />
        <DateField
          title={dateSyntheseLabel}
          name={'date'}
          value={moment(selectedSynthese.edition_date, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY')}
          calendarLabels={{ dayShortLabels, monthLabels }}
          onChange={(event) => {
            setSelectedSynthese(prevSelectedSynthese => ({ ...prevSelectedSynthese, edition_date: moment(event.value, 'DD/MM/YYYY').format('YYYY-MM-DD HH:mm:ss'), }))
          }}
          locale={locale}
          readOnly={readOnly}
        />
        <DescriptionTextArea
          name={'description'}
          placeholder={descriptionSyntheseLabel}
          value={selectedSynthese.content}
          onChange={(event) => {
            setSelectedSynthese(prevSelectedSynthese => ({ ...prevSelectedSynthese, content: event.value }))
          }}
          type="text"
          readonly={readOnly}
          maxLength={null}
        />
      </div >
    );
  };

  const handleSelectSynthese = (synthese) => {
    setIsOpenView(true);
    setSelectedSynthese(synthese);
  };

  const orderedSyntheses = (syntheses) => {
    if (!syntheses) {
      return []
    }

    return syntheses.sort((syntheseA, syntheseB) =>
      new Date(syntheseB.edition_date) - new Date(syntheseA.edition_date)
    )
  }

  return (
    <div className="accomp-collective-synthese-co-card">
      <div className="accomp-collective-synthese-co-card-header">
        <IconCardTitle icon="clipboard" color="accent" label={title} />
        {!readOnly &&
          <div className="circle-button-wrapper">
            <CircleButton icon="plus" onClick={() => setIsOpenEdit(true)} />
          </div>
        }
      </div>

      <div className="accomp-collective-synthese-co-card-content">
        {orderedSyntheses(syntheses).map((synthese) => (
          <div className="accomp-collective-synthese-co-card-item">
            <p>{synthese.title}</p>
            <div className="accomp-collective-synthese-co-card-item-actions">
              <p>{moment(synthese.edition_date).format('DD/MM/YYYY')}</p>

              <CircleButton
                icon="edit"
                color={'white'}
                onClick={() => handleSelectSynthese(synthese)}
              />

              <CircleButton
                icon="trash"
                color={'white'}
                onClick={() => handleDeleteSynthese(synthese)}
              />
            </div>
          </div>
        ))}

        <Modal
          onClosePopin={() => setIsOpenEdit(false)}
          openPopin={isOpenEdit}
          title={"Création d'une synthèse de suivi"}
          content={renderEditSynthesePopin()}
          cancelButton={'Annuler'}
          disabledActionButton={false}
          actionButton={'Valider'}
          handleActionButton={() =>
            handleCreateSynthese(createTitle, createDate, createDescription)
          }
        />
        <Modal
          onClosePopin={() => setIsOpenView(false)}
          openPopin={isOpenView}
          title={`Modification de la synthèse de suivi "${selectedSynthese?.title}"`}
          content={renderViewSynthesePopin()}
          cancelButton={'Annuler'}
          disabledActionButton={false}
          actionButton={'Valider'}
          handleActionButton={() =>
            handleEditSynthese(
              selectedSynthese?.id,
              selectedSynthese?.title,
              selectedSynthese?.edition_date,
              selectedSynthese?.content,
            )
          }
        />
      </div>
    </div>
  );
};

SyntheseCoCard.propTypes = {
  title: PropTypes.string,
};

export default SyntheseCoCard;
