import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { SpinnerCircular } from 'spinners-react';

import Page from '../../containers/global/Page/Page';
import BeneficiaryPageAside from '../../containers/beneficiary/BeneficiaryPageAside/BeneficiaryPageAside';
import LeftAsideLayout from '../../layouts/LeftAsideLayout/LeftAsideLayout';
import TabbedContentLayout from '../../layouts/TabbedContentLayout/TabbedContentLayout';

// Components
import LineMenu from '../../components/organisms/LineMenu/LineMenu';
import DataTable from '../../components/molecules/DataTable2/DataTable2';
import Icon from '../../components/atoms/Icon/Icon';
import PrimaryButton from '../../components/atoms/PrimaryButton/PrimaryButton';

// REDUX
import { selectors as UserSelectors } from '../../redux/UserRedux';
import {
  actions as NavigationActions,
  selectors as NavigationSelectors,
} from '../../redux/NavigationRedux';

// API
import { getListAISynthesisByBeneficiary } from '../../api/IAsynthesisApi';

const mapStateToProps = (state) => ({
  user: UserSelectors.user(state),
  pathname: NavigationSelectors.pathname(state),
});

const mapDispatchToProps = (dispatch) => ({
  redirect: (pathname) => dispatch(NavigationActions.push(pathname)),
});

const BeneficiaryIASynthesisPage = ({ ...props }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [listSynthesis, setListSynthesis] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    loadSynthesis(props.user.profile_id);
  }, []);

  async function loadSynthesis(profile_id) {
    try {
      const listSynthesis = await getListAISynthesisByBeneficiary(profile_id);
      if (!listSynthesis?.ai_synthesis) {
        throw new Error('Erreur lors de la récupération des synthèses.');
      }

      const transformedList = listSynthesis.ai_synthesis
        .filter(
          (synthesis) =>
            synthesis.visibilityByBeneficiary === true &&
            synthesis.status.includes('Finished', 'Signed'),
        )
        .map((synthesis) => ({
          id: synthesis.id,
          nameSynthesis: synthesis.name,
          status: synthesis.statusToDisplay,
          download: synthesis.status === 'ToCreate' ? false : true,
        }));

      setListSynthesis(transformedList);
      setError(null);
      setIsLoaded(true);
    } catch (error) {
      setError(error.message);
      setIsLoaded(true);
    }
  }

  const renderTabs = () => {
    const links = [{ id: 'ia-synthesis', label: 'Mes synthèses' }];
    return <LineMenu value="ia-synthesis" links={links} />;
  };

  const renderContent = () => {
    if (!isLoaded) {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: 'inherit',
            gap: '1rem',
          }}
        >
          <SpinnerCircular
            thickness={200}
            speed={150}
            color={Icon.color.Grey1}
            secondaryColor={Icon.color.Accent}
          />
          <p>Chargement en cours...</p>
        </div>
      );
    } else {
      return <DataTable data={listSynthesis} />;
    }
  };

  const aside = <BeneficiaryPageAside />;
  const content = <TabbedContentLayout header={renderTabs()} content={renderContent()} />;

  if (error) {
    return (
      <div className="error-container">
        {error || 'Le bénéficiaire est introuvable.'}
        <PrimaryButton id={'closeButton'} label={'OK'} onClick={() => setError(null)} />
      </div>
    );
  }

  return (
    <div className="beneficiary-courses-page">
      <LeftAsideLayout aside={aside} content={content} />
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Page(BeneficiaryIASynthesisPage));
