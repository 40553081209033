import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import QuizStep from '../QuizStep/QuizStep';
import PrimaryButton from '../../atoms/PrimaryButton/PrimaryButton';
import './QuizYesNo.scss';
class QuizYesNo extends Component {
  static propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    stepLabel: PropTypes.string,
    stepOutline: PropTypes.bool,
    value: PropTypes.bool,
    yesLabel: PropTypes.string.isRequired,
    noLabel: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    image: PropTypes.string,
  };

  static defaultProps = {
    onChange: () => undefined,
    stepLabel: null,
    disabled: false,
    value: null,
  };

  handleYesClick = async () => {
    const { id, value, onChange, disabled } = this.props;
    if (!disabled) {
      let newValue = true;

      if (value === true) {
        newValue = -1;
      }
      await onChange({ id, value: newValue });
    }
  };

  handleNoClick = () => {
    const { id, value, onChange, disabled } = this.props;

    if (!disabled) {
      let newValue = false;

      if (value === false) {
        newValue = '';
      }

      onChange({ id, value: newValue });
    }
  };
  
  render() {
    const { stepLabel, label, stepOutline, value, id, yesLabel, noLabel, disabled, image } =
      this.props;
    return (
      <div className={classnames('quiz-yes-no', disabled && 'disabled')}>
        <QuizStep stepLabel={stepLabel} stepOutline={stepOutline} label={label} />
        {image && <img src={image} alt="question_illu" className="question-illustration" />}
        <div className="buttons">
          <PrimaryButton
            label={yesLabel}
            id={`${id}_yes`}
            onClick={() => this.handleYesClick()}
            outline={value === false || value === ''}
          />
          <PrimaryButton
            label={noLabel}
            id={`${id}_no`}
            onClick={() => this.handleNoClick()}
            outline={value === true || value === ''}
          />
        </div>
      </div>
    );
  }
}

export default QuizYesNo;
