import React from 'react'
import {
  needToFillFinancerInfo,
  waitingCreationValidation,
  waitingPersonnalInfoValidation,
} from '../../../helpers/beneficiaryStatus'
import InfoPage from '../../../components/molecules/InfoPage/InfoPage'
import BeneficiaryProfileForm from './BeneficiaryProfileForm'

const BeneficiaryProfile = ({ user }) => {
  const renderStatusMessage = () => {
    let message = null

    if (waitingCreationValidation(user)) {
      message = "Vous n'avez aucun engagement avec nous actuellement. Vous pouvez contacter votre consultant par message ou par téléphone."
    }

    if (needToFillFinancerInfo(user)) {
      message = "Vous devez remplir vos moyens de financement"
    }

    if (waitingPersonnalInfoValidation(user)) {
      message = "Vous devez compléter et valider vos informations personnelles"
    }

    if (message !== null) {
      return <InfoPage text={message} />
    }

    return null
  }

  if (!user) {
    return null
  }

  return (
    <div className="beneficiary-profile">
      {renderStatusMessage()}
      <BeneficiaryProfileForm user={user} />
    </div>
  )
}

export default BeneficiaryProfile