import React, { useEffect, useState } from "react"
import { Popin } from '../../global/Popin/Popin'
import { getAllPublishModulesContainer, getAllPublishMyTalentsModulesContainer, getAllPublishOfficeModulesContainer } from "../../../api/TemplateCourseApi";
import { selectors as UserSelectors } from "../../../redux/UserRedux"
import { connect } from "react-redux";
import { duplicateModuleContainer } from "../../../api/CourseApi";
import CircleButton from "../../../components/atoms/CircleButton/CircleButton";
import Icon from "../../../components/atoms/Icon/Icon";
import "./ExistingModuleContainerPopin.scss";
import SearchField from "../../../components/molecules/SearchField/SearchField";
import LoadingSpinner from "../../../components/atoms/LoadingSpinner/LoadingSpinner";
import BigCombo from "../../../components/molecules/BigCombo/BigCombo";
import IconButton from "../../../components/atoms/IconButton/IconButton";
import { containsSearchValue } from "../../../api/utils/StringUtils";

const mapStateToProps = (state) => ({
    user: UserSelectors.user(state),
})

const ExistingModuleContainerPopin = ({ handleClosePopin, isOpen, user, setModuleContainerChosen, stepId }) => {
    const [modulesContainers, setModulesContainers] = useState([]);
    const [modulesContainersOrigin, setModulesContainersOrigin] = useState([]);
    const [search, setSearch] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [moduleOwnerFilter, setModuleOwnerFilter] = useState("all");
    const modulesOwnersFilters = [{ label: "Mes modules", value: "user" }, { label: "Nos modules", value: "office" }, { label: "Modules My Talents", value: "mytalents" }, { label: "Tous les modules", value: "all" }]

    const getAllModulesContainer = () => {
        setIsLoading(true);
        Promise.all([
            getAllPublishModulesContainer(user.id),
            getAllPublishMyTalentsModulesContainer(),
            getAllPublishOfficeModulesContainer(user.id)
        ]).then(([userResponse, myTalentsResponse, officeResponse]) => {
            const userModulesContainer = userResponse.modules_containers;
            const myTalentsModulesContainer = myTalentsResponse.modules_containers;
            const officeModulesContainer = officeResponse.modules_containers;

            setModulesContainers({
                user: userModulesContainer,
                mytalents: myTalentsModulesContainer,
                office: officeModulesContainer,
            });

            setModulesContainersOrigin({
                user: userModulesContainer,
                mytalents: myTalentsModulesContainer,
                office: officeModulesContainer,
            });
        }).finally(() => {
            setIsLoading(false);
        });
    }

    const chooseModuleContainer = (moduleContainer) => {
        moduleContainer.consultant_id = user.profile_id;
        moduleContainer.step_id = stepId
        duplicateModuleContainer(moduleContainer).then((response) => {
            setModuleContainerChosen(response.modules_container);
        })
    }

    const handleFiltersChange = (filter) => {
        if (filter.id === "ressources_owner") {
            setModuleOwnerFilter(filter.value.value);
            filterOwnerRessource(filter.value.value, search)
        }
        else {
            setSearch(filter.value)
            filterOwnerRessource(moduleOwnerFilter, filter.value)
        }
    }

    const filterOwnerRessource = (owner, searchValue) => {
        if (owner !== "all") {
            const obj = modulesContainersOrigin;
            const filteredResources = Object.keys(obj).map(key => {
                if (key !== owner) {
                    return { [key]: [] };
                }
                else {
                    return { [key]: obj[key] };
                }
            }).reduce((acc, curr) => Object.assign(acc, curr), {});
            filterByTitle(searchValue, filteredResources)
        }
        else {
            filterByTitle(searchValue, modulesContainersOrigin)
        }
    }

    const filterByTitle = (searchValue, object) => {
        if (searchValue) {
            const filteredResources = Object.fromEntries(
                Object.entries(object).reduce((acc, [key, value]) => {
                    if (value) {
                        const filteredValues = value.filter(element => element.title && containsSearchValue(element.title, searchValue));
                        if (filteredValues.length > 0) {
                            acc.push([key, filteredValues]);
                        } else {
                            acc.push([key, []]);
                        }
                    } else {
                        acc.push([key, []]);
                    }
                    return acc;
                }, [])
            );
            setModulesContainers(filteredResources)
        }
        else {
            setModulesContainers(object)
        }
    }

    useEffect(() => {
        getAllModulesContainer();
    }, [])

    const renderHeader = () => {
        return (
            <div className="published-modules-container-popin-header">
                <h1>Ajouter un module existant</h1>
            </div>
        )
    }

    const renderModuleContainerRow = (moduleContainer, index) => {
        const { id, title } = moduleContainer
        return (
            <div className="module-container-row" id={id} key={index} onClick={() => chooseModuleContainer(moduleContainer)}>
                <p>{title}</p>
                <CircleButton icon={Icon.icon.Plus} />
            </div>
        )
    }

    const renderListModulesContainers = () => {
        return (
            <div className="all-modules-containers">
                {Object.keys(modulesContainers)[0] === "user" && modulesContainers["user"]?.length > 0 &&
                    <div>
                        <label>Mes modules</label>
                        {modulesContainers["user"]?.length > 0 && modulesContainers["user"]?.map((moduleContainer, index) => (
                            renderModuleContainerRow(moduleContainer, index)
                        ))}
                    </div>
                }

                {Object.keys(modulesContainers)[2] === "office" && modulesContainers["office"]?.length > 0 &&
                    <div>
                        <label>Nos modules</label>
                        {modulesContainers["office"]?.length > 0 && modulesContainers["office"]?.map((moduleContainer, index) => (
                            renderModuleContainerRow(moduleContainer, index)
                        ))}
                    </div>
                }

                {Object.keys(modulesContainers)[1] === "mytalents" && modulesContainers["mytalents"]?.length > 0 &&
                    <div>
                        <label>Modules My Talents</label>
                        {modulesContainers["mytalents"]?.length > 0 && modulesContainers["mytalents"]?.map((moduleContainer, index) => (
                            renderModuleContainerRow(moduleContainer, index)
                        ))}
                    </div>
                }
            </div>
        )
    }

    const renderAllFilters = () => {
        return (
            <div className="all-filters">
                <div className="all-filters-title">
                    <IconButton icon={Icon.icon.Setting} />
                    <p>FILTRES</p>
                </div>
                <div className="all-filters-content">

                    <SearchField placeholder={"Rechercher par nom de module"} showLine onChange={handleFiltersChange} value={search} />
                    <BigCombo id={"ressources_owner"} options={modulesOwnersFilters} value={moduleOwnerFilter} onChange={handleFiltersChange} />
                </div>

            </div>
        )
    }

    return (
        <Popin onClose={handleClosePopin} open={isOpen} focusTrapped={false}>
            <div className="published-modules-container-popin">
                {renderHeader()}
                <div>
                    {isLoading ? <LoadingSpinner /> :
                        <div className="published-modules-container-popin-content">
                            {renderAllFilters()}
                            {renderListModulesContainers()}
                        </div>
                    }
                </div>
            </div>
        </Popin>
    )
}

export default connect(mapStateToProps, null)(ExistingModuleContainerPopin);