import { axiosInstance } from "./baseApi";

export const getMessagesInRoom = async (room_key) => {
    const response = await axiosInstance.get(`/chat/room/messages`, { params: { room_key } });
    return response.data;
}

export const getLastMessageInRooms = async (room_keys) => {
    const response = await axiosInstance.get(`/chat/rooms/last-message`, { params: { room_keys } });
    return response.data;
}

export const updateRoomNotification = async (room_key, notification) => {
    const response = await axiosInstance.put(`/chat/room/update-notification`, { room_key, notification });
    return response.data;
}

export const checkRoomNotification = async (id_user) => {
    const response = await axiosInstance.get(`/chat/room/check-notification`, { params: { id_user } });
    return response.data;
}

export const syncRooms = async (id_user, user_role) => {
    const response = await axiosInstance.post(`/chat/room/create`, { id_user, user_role });
    return response.data;
}

export const sendMessage = async (message) => {
    const response = await axiosInstance.post('/chat/message/send', message);
    return response.data;
}

export const deleteMessage = async (id_message, random_key_message, room_key) => {
    const response = await axiosInstance.delete('/chat/message/delete', { params: { id_message, random_key_message, room_key } });
    return response.data;
}
