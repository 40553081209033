import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { selectors as LocaleSelectors } from '../../redux/LocaleRedux'
import { selectors as UserSelectors } from '../../redux/UserRedux'
import { actions as BeneficiaryActions } from '../../redux/BeneficiaryRedux'

import Logo from '../../static/assets/logo-my-talents.svg'
import WelcomePopin from '../../components/organisms/WelcomePopin/WelcomePopin'

import 'moment/locale/fr'
import "./BeneficiaryInValidationPage.scss"
import { getActivityOptions, getEducationOptions, getSituationOptions, getSocioProfessionalCategoriesOptions } from '../../api/utils/OptionsConst'
const mapStateToProps = (state) => ({
  user: UserSelectors.user(state),
  locale: LocaleSelectors.locale(state),
})

const mapDispatchToProps = (dispatch) => ({
  saveCurrentBeneficiary: (beneficiary) =>
    dispatch(BeneficiaryActions.saveCurrentBeneficiary(beneficiary)),
})

class BeneficiaryInValidationPage extends Component {


  handleChange = (value) => {
    const newValues = {
      ...this.state.values,
      [value.id]: value.value,
    }

    this.setState({
      values: newValues,
    })
  }

  handleSubmit = async () => {
    const { user, saveCurrentBeneficiary } = this.props

    const newUser = {
      ...user,
      birth_date: moment(this.state.values.birthdate, 'DD/MM/YYYY').format('YYYY-MM-DD'),

      address: this.state.values.address,
      zip_code: this.state.values.zip_code,
      city: this.state.values.city,

      cursus_formation: this.state.values.education,

      cursus_professional_situation: this.state.values.situation,
      cursus_socio_professional_category: this.state.values.socio_professional_category,
      diploma_name: this.state.values.diploma_name,
      cursus_function: this.state.values.cursus_function,
      cursus_company_activity_sector: this.state.values.activity,
    }

    await saveCurrentBeneficiary(newUser)

    setTimeout(() => {
      window.location.href = '/';
    }, "500")
  }

  buildSteps = () => {
    const { locale } = this.props

    let disabledNextButtonStep1 = false

    const displayFormat = 'DD/MM/YYYY'

    if (this.state.values.birthdate === '' || this.state.values.birthdate == null) {
      disabledNextButtonStep1 = true
    }
    const date = moment(this.state.values.birthdate, displayFormat)

    if (
      this.state.values.birthdate !== null &&
      (this.state.values.birthdate.length !== displayFormat.length ||
        date.format() === 'Invalid Date')
    ) {
      disabledNextButtonStep1 = true
    }

    const disabledNextButtonStep2 =
      this.state.values.address === '' ||
      this.state.values.zip_code === '' ||
      this.state.values.city === ''

    const disabledNextButtonStep3 = false

    const educationComboContent = [
      {
        title: "Niveau de formation *",
        id: 'education',
        placeholder: "Veuillez choisir votre niveau de formation",
        options: getEducationOptions(),
        value: this.state.values.education,
      },
    ]

    let disabledNextButtonStep4 = false

    const aboutContent = [
      {
        comboContent: [
          {
            title: "Votre situation professionnelle *",
            id: 'situation',
            placeholder: "Veuillez choisir la situation professionnelle",
            options: getSituationOptions(),
            value: this.state.values.situation,
          },
        ],
      },
    ]

    if (this.state.values.situation === 'cdd' || this.state.values.situation === 'cdi') {
      aboutContent.push({
        comboContent: [
          {
            title: "Catégorie socioprofessionnelle *",
            id: 'socio_professional_category',
            placeholder: "Veuillez choisir votre catégorie socioprofessionnelle",
            options: getSocioProfessionalCategoriesOptions(),
            value: this.state.values.socio_professional_category,
          },
        ],
      })

      aboutContent.push({
        inputContent: {
          inputTitle: "Titre du diplôme *",
          placeholder: '',
          inputName: 'diploma_name',
          inputValue: this.state.values.diploma_name,
        },
      })

      aboutContent.push({
        inputContent: {
          inputTitle: "Fonctions *",
          placeholder: '',
          inputName: 'cursus_function',
          inputValue: this.state.values.cursus_function,
        },
      })

      aboutContent.push({
        comboContent: [
          {
            title: "Secteur d'activité",
            id: 'activity',
            placeholder: "Veuillez choisir le secteur d'activité",
            options: getActivityOptions(),
            value: this.state.values.activity,
          },
        ],
      })

      if (
        this.state.values.socio_professional_category === null ||
        this.state.values.diploma_name === '' ||
        this.state.values.cursus_function === '' ||
        this.state.values.activity === null
      ) {
        disabledNextButtonStep4 = true
      }
    }

    return [
      {
        steps: 4,
        current: 1,
        mainTitle: "Félicitations",
        content: "Plus que quelques informations personnelles à compléter avant le début de votre accompagnement.",
        inputType: WelcomePopin.inputTypes.birthday,
        datePicker: {
          title: "Votre date de naissance *",
          placeholder: "JJ/MM/AAAA",
          name: 'birthdate',
          value: this.state.values.birthdate,
          dayShortLabels: ["Lun", "Mar", "Mer", "Jeu", "Ven", "Sam", "Dim"],
          monthLabels: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"],
          locale,
        },
        nextId: 'next-step',
        next: "Suivant",
        onNext: () => {
          this.setState({
            step: 1,
          })
        },
        disabledNextButton: disabledNextButtonStep1,
      },
      {
        steps: 4,
        current: 2,
        mainTitle: "Félicitations",
        content: "Plus que quelques informations personnelles à compléter avant le début de votre accompagnement.",
        inputType: WelcomePopin.inputTypes.address,
        addressInputs: [
          {
            inputTitle: "Votre addresse *",
            placeholder: '',
            inputName: 'address',
            inputValue: this.state.values.address,
          },
          {
            inputTitle: "Votre code postal *",
            placeholder: '',
            inputName: 'zip_code',
            inputValue: this.state.values.zip_code,
          },
          {
            inputTitle: "Votre ville *",
            placeholder: '',
            inputName: 'city',
            inputValue: this.state.values.city,
          },
        ],
        prevId: 'prev-step',
        nextId: 'next-step',
        prev: "Précédent",
        next: "Suivant",
        onPrev: () => {
          this.setState({
            step: 0,
          })
        },
        onNext: () => {
          this.setState({
            step: 2,
          })
        },
        disabledNextButton: disabledNextButtonStep2,
      },
      {
        steps: 4,
        current: 3,
        mainTitle: "Félicitations",
        content: "Plus que quelques informations personnelles à compléter avant le début de votre accompagnement.",
        inputType: WelcomePopin.inputTypes.education,
        comboContent: educationComboContent,
        prevId: 'prev-step',
        nextId: 'next-step',
        prev: "Précédent",
        next: "Suivant",
        onPrev: () => {
          this.setState({
            step: 1,
          })
        },
        onNext: () => {
          this.setState({
            step: 3,
          })
        },
        disabledNextButton: disabledNextButtonStep3,
      },
      {
        steps: 4,
        current: 4,
        mainTitle: "Félicitations",
        content: "Plus que quelques informations personnelles à compléter avant le début de votre accompagnement.",
        inputType: WelcomePopin.inputTypes.about,
        aboutContent,
        prevId: 'prev-step',
        nextId: 'next-step',
        prev: "Précédent",
        next: "Valider",
        onPrev: () => {
          this.setState({
            step: 2,
          })
        },
        onNext: this.handleSubmit,
        disabledNextButton: disabledNextButtonStep4,
      },
    ]
  }

  constructor(props) {
    super(props)

    this.state = {
      step: 0,
      user_id: null,
      values: {
        birthdate: null,
        address: '',
        zip_code: '',
        city: '',
        education: getEducationOptions()[0].value,
        situation: getSituationOptions()[0].value,
        socio_professional_category: null,
        diploma_name: '',
        cursus_function: '',
        activity: null,
      },
    }
  }

  static getDerivedStateFromProps({ user }, state) {
    if (user && state.user_id === null) {
      return {
        user_id: user.id,
        values: {
          birthdate: user.birth_date
            ? moment(user.birth_date, 'YYYY-MM-DD').format('DD/MM/YYYY')
            : null,
          address: user.address || '',
          zip_code: user.zip_code || '',
          city: user.city || '',
          education: user.cursus_formation || getEducationOptions()[0].value,
          situation: user.cursus_professional_situation || getSituationOptions()[0].value,
          socio_professional_category: user.cursus_socio_professional_category || null,
          diploma_name: user.diploma_name || '',
          cursus_function: user.cursus_function || '',
          activity: user.cursus_company_activity_sector || null,
        },
      }
    }

    return null
  }

  render() {
    const { user } = this.props

    if (!user) {
      return null
    }

    const steps = this.buildSteps()

    return (
      <div className="beneficiary-invalidation-page">
        <Logo className="logo" />

        <WelcomePopin {...steps[this.state.step]} onChange={this.handleChange} />
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BeneficiaryInValidationPage)