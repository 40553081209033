import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux'
import { selectors as UserSelectors } from '../../../redux/UserRedux';

import AvatarCircle from "../AvatarCircle/AvatarCircle";
import Icon from "../Icon/Icon";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useNavigation } from "../../../hooks/useNavigation";
import { selectors as OfficeSelectors } from '../../../redux/OfficeRedux'
import { selectors as portalSelectors } from '../../../redux/PortalRedux'
import { getLinks } from '../../../containers/consultant/ConsultantPageAside/ConsultantPageAside'
import './Navbar.scss';
import { checkRoomNotification } from "../../../api/ChatApi";

export const Navbar = ({ leftComponentNavbar, aside }) => {
    const user = useSelector(UserSelectors.user);
    const location = useLocation();
    const { redirect } = useNavigation();
    const [notification, setNotification] = useState(false);
    const pathSegments = location.pathname.split("/");
    const firstPartUrl = pathSegments[1];
    const [currentUser, setCurrentUser] = useState({ name: "", avatar: "", role: "" });
    const office = useSelector(OfficeSelectors.office)
    const isActive = useSelector(portalSelectors.isActive)
    const [mobileActive, setMobileActive] = useState(false);


    const handleClickProfile = () => {
        user.role === "manager" ? redirect("/" + firstPartUrl + "/office") : redirect("/" + firstPartUrl + "/profile");
    }

    const handleClickMessage = () => {
        setNotification(false);
        redirect("/" + firstPartUrl + "/messages");
    }

    const handleClickBurgerMenu = () => {
        setMobileActive(!mobileActive);
    }

    const getTitleLink = () => {
        let links = getLinks();
        links.push({
            id: '/beneficiary/courses',
            label: "Parcours",
            icon: Icon.icon.Paperboard,
        })
        links.push({
            id: '/beneficiary/calendar',
            label: "Agenda",
            icon: Icon.icon.Calendar,
        })
        links.push({
            id: '/manager/consultants',
            label: "Consultants",
            icon: Icon.icon.People,
        });

        links.push({
            id: '/manager/beneficiaries/me',
            label: "Talents",
            icon: Icon.icon.Free,
        });

        links.push({
            id: '/manager/statistics',
            label: "Statistiques",
            icon: Icon.icon.Piechart,
        });

        links.push({
            id: '/manager/office',
            label: "Cabinet",
            icon: Icon.icon.Building,
        });

        const link = links.find(link => location.pathname.includes(link.id));
        return link ? link.label : "";
    }

    useEffect(() => {
        switch (user.role) {

            case 'manager':
                setCurrentUser({ name: user.office.name, avatar: user.office.image_url, role: user.role })
                break;

            case 'consultant':
            case 'beneficiary':

                setCurrentUser({ name: `${user.first_name} ${user.last_name}`, avatar: user.image_url ? user.image_url : null, role: user.role })
                break;

            default:
                setCurrentUser({ name: "", avatar: "", role: "" })
                break;
        }
    }, [user])

    useEffect(() => {
        if (location.pathname !== ("/" + firstPartUrl + "/messages")) {
            checkRoomNotification(user.id).then(function (res) {
                if (res[0].notification !== null && res[0].id_receiver === user.id) {
                    setNotification(true);

                    const obj = {
                        room_keys: [res[0].cm_room_key],
                        id_receiver: res[0].id_receiver,
                        notification: true
                    }
                    localStorage.setItem("mtChatNotification", JSON.stringify(obj));
                }

            }).catch(function (err) {
            })
        }
    }, [firstPartUrl, location.pathname, user.id])

    const messagerie = (number = "!") => {
        if (notification) {
            return (
                <>
                    <div className="red-dot" />
                    <Icon icon={Icon.icon.Message} color={Icon.color.Accent} />
                    <p className="message-alert">Nouveau message</p>
                    <p className="message-number">{number}</p>
                </>
            )
        } else {
            return (
                <>
                    <Icon icon={Icon.icon.Message} color={Icon.color.Accent} />
                    <p>Messagerie</p>
                </>
            )
        }
    }

    const renderHeader = () => {
        if (!office || !currentUser) {
            return null
        }
        return (
            <>
                <div className="logos" style={{ display: 'flex', alignItems: 'center' }}>
                    {(!isActive && currentUser.role !== "beneficiary") && (
                        <>
                            <img
                                className="mytalents-logo"
                                alt="Logo"
                                src="/static/logos/logo-my-talents-dark-mobile.svg"
                                width={60}
                                height={60}
                            />
                            <div className="separator" />

                        </>
                    )}
                    {office.image_url && <img className='office-logo' src={`${process.env.REACT_APP_ASSETS_API_STATIC_URL}${(office?.image_url).replace(process.env.REACT_APP_OLD_UPLOADS_API_STATIC_URL, '')}`} alt={office.name} />}
                </div>
            </>
        )
    }

    return (
        <>
            <div className="menu-mobile" style={{ left: mobileActive ? "0" : "-100%" }}>
                <div className="left-aside-layout">
                    <div className="aside open mobile">
                        {aside}
                    </div>
                </div>
            </div>
            <div className={leftComponentNavbar ? "navbar-space-between" : "navbar"}>
                {leftComponentNavbar &&
                    <>
                        {leftComponentNavbar}
                    </>
                }
                <div className="resources">
                    <div className="resources-header-mobile">
                        {renderHeader()}
                    </div>
                    <h3 className="resources-title-mobile">{getTitleLink()}</h3>
                    <div className="resources-btns-wrapper">
                        <div type="button" className="resources-btn resources-profile" onClick={handleClickProfile}>
                            <div className="resources-avatar">
                                <AvatarCircle photoUrl={currentUser.avatar} />
                            </div>
                            <p>{currentUser.name}</p>
                        </div>
                        <div type="button" className="resources-btn resources-msg" onClick={handleClickMessage}>
                            {messagerie()}
                        </div>
                        <div type="button" className="resources-btn resources-alert">
                        </div>
                        <div type="button" className="resources-btn resources-burger" onClick={handleClickBurgerMenu}>
                            <Icon icon={Icon.icon.BurgerMenu} color={Icon.color.Accent} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}