/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SpinnerCircular } from 'spinners-react';

import ConsultantBeneficiary from '../../containers/consultant/ConsultantBeneficiary/ConsultantBeneficiary';

import HeaderIllustration from '../../static/illustrations/Parcours.png';
import './ConsultantBeneficiaryIASynthesisPage.scss';

// Components
import HeaderConsultantTab from '../../components/atoms/HeaderConsultantTab/HeaderConsultantTab';
import DataTable2 from '../../components/molecules/DataTable2/DataTable2';
import AiSynthesisNameEdition from '../../components/molecules/AISynthesisNameEdition/AISynthesisNameEdition';
import AISynthesisCreation from '../../components/molecules/AISynthesisCreation/AISynthesisCreation';
import AISynthesisSection from '../../components/molecules/AISynthesisSection/AISynthesisSection';
import { Modal } from '../../components/atoms/Modal/Modal';
import CircleButton from '../../components/atoms/CircleButton/CircleButton';
import PrimaryButton from '../../components/atoms/PrimaryButton/PrimaryButton';
import Icon from '../../components/atoms/Icon/Icon';

// REDUX
import {
  actions as BeneficiaryActions,
  selectors as BeneficiarySelectors,
} from '../../redux/BeneficiaryRedux';
import { actions as UserActions, selectors as UserSelectors } from '../../redux/UserRedux';
import {
  actions as NavigationActions,
  selectors as NavigationSelectors,
} from '../../redux/NavigationRedux';

// API
import {
  getListAISynthesisByBeneficiary,
  editSynthesis,
  deleteSynthesis,
  getSynthesisById,
} from '../../api/IAsynthesisApi';

const mapStateToProps = (state, props) => ({
  id: props.match.params.user_id, // Récupération d'un paramètre d'URL
  pathname: NavigationSelectors.pathname(state),
  beneficiary: BeneficiarySelectors.beneficiary(state),
  consultant_id: UserSelectors.user(state).profile_id,
});

const mapDispatchToProps = (dispatch) => ({
  redirect: (pathname) => dispatch(NavigationActions.push(pathname)),
  getBeneficiary: (id) => dispatch(BeneficiaryActions.getBeneficiary(id)),
});

class ConsultantBeneficiaryIASynthesisPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isOpenAdd: false,
      editMode: false,
      isHovered: false,
      listSynthesis: [],
      selectedSynthesisId: null,
      editedName: { edited: false, newName: '' },
      isButtonEnabled: false,
      error: null,
    };
  }

  async componentDidMount() {
    const { pathname, id, getBeneficiary, redirect } = this.props;

    if (!/\/(ia-synthesis)$/.test(pathname)) {
      redirect(`${pathname}/`);
    }

    try {
      await getBeneficiary(id);

      await this.checkBeneficiaryLoaded();
    } catch (error) {
      this.setState({ error: 'Erreur lors de la récupération du bénéficiaire.', isLoading: false });
    }
  }

  componentDidUpdate(prevProps) {
    const { beneficiary } = this.props;
    if (prevProps.beneficiary !== beneficiary) {
      this.checkBeneficiaryLoaded();
    }
  }

  /**
   * Checks if the beneficiary data is loaded and valid.
   * If the beneficiary has a profile ID, it triggers the loading
   * of AI synthesis data for that beneficiary.
   */
  async checkBeneficiaryLoaded() {
    const { beneficiary } = this.props;
    if (beneficiary && beneficiary.profile_id) {
      await this.loadSynthesis(beneficiary.profile_id);
    }
  }

  /**
   * Loads the AI synthesis data for a given beneficiary profile.
   * Fetches the synthesis list using the beneficiary's profile ID and transforms
   * the data for display in DataTable2. Updates the component's state with the
   * transformed data or sets an error state if the fetch fails.
   *
   * @param {string} profile_id - The ID of the beneficiary profile to load synthesis for.
   */
  async loadSynthesis(profile_id) {
    try {
      const listSynthesis = await getListAISynthesisByBeneficiary(profile_id);
      if (!listSynthesis?.ai_synthesis) {
        throw new Error('Erreur lors de la récupération des synthèses.');
      }

      // Transformer les données pour DataTable2
      const transformedList = listSynthesis.ai_synthesis.map((synthesis) => ({
        id: synthesis.id,
        nameSynthesis: synthesis.name,
        statusId: synthesis.status,
        status: synthesis.statusToDisplay,
        statusReason: synthesis.statusReason,
        actions: synthesis.status === 'ToCreate' ? undefined : () => this.setEditMode(synthesis.id),
        download: synthesis.status === 'ToCreate' ? false : true,
        visibility: synthesis.visibilityByBeneficiary,
        editVisible: () => this.setEditVisibility(synthesis.id),
        deleteSynthesis: () => this.deleteSynthesis(synthesis.id),
      }));

      this.setState({ listSynthesis: transformedList, isLoading: false, error: null });
    } catch (error) {
      this.setState({ error: error.message, isLoading: false });
    }
  }

  /**
   * Updates the component's state with the provided synthesis data.
   *
   * @param {Object} updatedSynthesis - The new synthesis data to set in edit mode.
   */
  handleChildUpdate = (updatedSynthesis) => {
    this.setState({ editMode: updatedSynthesis });
  };

  /**
   * Toggles the state of the modal and refreshes the synthesis list if the modal is closed.
   *
   * @param {boolean} value - Indicates whether the modal should be open or closed.
   * @returns {Promise<void>} - A promise that resolves when the synthesis list is refreshed.
   */
  setOpenModal = async (value) => {
    const { beneficiary } = this.props;
    if (!value) {
      await this.loadSynthesis(beneficiary.profile_id);
    }
    this.setState({ isOpenAdd: value });
  };

  /**
   * Sets the edit mode for a synthesis based on the provided synthesis ID.
   * If the synthesis ID is not provided or the synthesis is not found,
   * the edit mode is disabled and an error is thrown.
   * Retrieves synthesis data from the API and updates the state with the data.
   *
   * @param {string} synthesis_id - The ID of the synthesis to edit.
   * @throws Will throw an error if the synthesis is not found or if there is an error loading the synthesis data.
   */
  setEditMode = async (synthesis_id) => {
    const { listSynthesis, editedName } = this.state;
    if (!synthesis_id) {
      if (editedName.newName) {
        const confirm = window.confirm(
          'Le nom de la syntèse a été modifié. Voulez-vous sauvegarder ces changements ?',
        );
        if (!confirm) {
          this.setState({ editMode: false, selectedSynthesisId: null });
          return;
        }

        await this.handleSaveSynthesis();
      }
      this.setState({ editMode: false, selectedSynthesisId: null });
    } else {
      const synthesis = listSynthesis.find((item) => item.id === synthesis_id);
      if (!synthesis) {
        this.setState({
          error: 'Synthèse introuvable.',
          editMode: false,
          selectedSynthesisId: null,
        });
      }

      const getSynthesisData = await getSynthesisById(synthesis.id);
      const synthesisData = getSynthesisData.ai_synthesis;
      if (!synthesisData) {
        this.setState({
          error: 'Erreur de chargement des données de la synthèse.',
          editMode: false,
          selectedSynthesisId: null,
        });
      }

      this.setState({
        editMode: synthesisData,
        selectedSynthesisId: synthesis_id,
        isButtonEnabled:
          synthesisData.status === 'InProgress' || synthesisData.status === 'ToReview',
      });
    }
  };

  /**
   * Toggles the visibility of a synthesis for a given beneficiary.
   *
   * @param {string} synthesis_id - The ID of the synthesis to update.
   * @throws Will throw an error if the synthesis is not found or if the update fails.
   *
   * Updates the state with the new visibility status of the synthesis.
   */
  setEditVisibility = async (synthesis_id) => {
    const { listSynthesis } = this.state;
    try {
      const synthesis = listSynthesis.find((item) => item.id === synthesis_id);
      const currentVisibility = synthesis.visibility;
      if (!synthesis) {
        throw new Error('Synthèse introuvable.');
      }

      const response = await editSynthesis(synthesis_id, {
        visibilityByBeneficiary: !currentVisibility,
      });
      if (!response) {
        throw new Error('Erreur lors de la mise à jour de la visibilité.');
      }

      const updatedList = listSynthesis.map((item) =>
        item.id === synthesis_id ? { ...item, visibility: !currentVisibility } : item,
      );
      this.setState({ listSynthesis: updatedList });
    } catch (error) {
      this.setState({ error: error.message });
    }
  };

  /**
   * Deletes a synthesis by its ID after user confirmation.
   * Updates the state with the new list of syntheses excluding the deleted one.
   * If an error occurs during deletion, it sets the error message in the state and logs it to the console.
   *
   * @param {string} synthesis_id - The ID of the synthesis to be deleted.
   * @returns {void}
   */
  deleteSynthesis = async (synthesis_id) => {
    const { listSynthesis } = this.state;

    try {
      const confirmDelete = window.confirm(
        'Êtes-vous sûr de vouloir supprimer cette synthèse ? Cette action est irréversible.',
      );
      if (!confirmDelete) {
        return;
      }

      const response = await deleteSynthesis(synthesis_id);
      if (!response) {
        throw new Error('Erreur lors de la suppression de la synthèse.');
      }

      const updatedList = listSynthesis.filter((item) => item.id !== synthesis_id);
      this.setState({ listSynthesis: updatedList });
    } catch (error) {
      this.setState({ error: error.message });
    }
  };

  /**
   * Updates the synthesis name in the component's state.
   *
   * @param {string} editedName - The new name for the synthesis.
   */
  updateSynthesisName = (editedName) => {
    this.setState({ editedName: { edited: true, newName: editedName } });
  };

  /**
   * Handles the saving of an AI synthesis by updating its name.
   * Validates the new name and updates the synthesis via API call.
   * If the update is successful, resets the state and checks if the beneficiary is loaded.
   * Displays an error message if the name is not provided or if the update fails.
   */
  handleSaveSynthesis = async () => {
    const { editedName, selectedSynthesisId } = this.state;
    if (!editedName.newName) {
      this.setState({ error: 'Veuillez entrer un nom pour la synthèse.' });
      return;
    }

    const response = await editSynthesis(selectedSynthesisId, { name: editedName.newName });
    if (!response.ai_synthesis) {
      this.setState({ error: 'Erreur lors de la mise a jour du nom de la synthèse.' });
      return;
    }

    this.setState({ selectedSynthesisId: null, editedName: { edited: false, newName: '' } });
    this.checkBeneficiaryLoaded();
  };

  /**
   * Handles the status update of a synthesis. If the synthesis is not in 'InProgress' status,
   * prompts the user for confirmation before marking it as 'Finished'. If confirmed, updates
   * the synthesis status using the editSynthesis API call. Displays an error message if the
   * update fails and resets the selected synthesis state upon success.
   */
  handleStatusSynthesis = async () => {
    const { editMode, selectedSynthesisId } = this.state;

    if (editMode.aiSynthesisSection.some((item) => item.modifiedText !== item.generatedText)) {
      const confirm = window.confirm(
        "Une fois le document marqué 'Terminé', le bénéficiaire pourra y accéder. Êtes-vous certain de vouloir faire cette opération ?",
      );
      if (!confirm) {
        return;
      }
    } else if (editMode.status === 'ToReview') {
      const confirm = window.confirm(
        "Vous n'avez apporté aucune modification à cette synthèse générée à l'aide d'un assistant IA.\n\nRappelons qu'un système d'IA, bien qu'avancé, reste un outil d'aide et peut comporter des erreurs ou approximations.\n\nÊtes-vous sûr(e) de vouloir partager cette synthèse avec votre bénéficiaire sans la réviser?",
      );
      if (!confirm) {
        return;
      }
    }

    const response = await editSynthesis(selectedSynthesisId, { status: 'Finished' });
    if (!response.ai_synthesis) {
      this.setState({ error: 'Erreur lors de la mise a jour du statut de la synthèse.' });
      return;
    }
    this.setState({ selectedSynthesisId: null, editMode: false });
    this.checkBeneficiaryLoaded();
  };

  handleIsHovered = (hovered) => {
    this.setState({ isHovered: hovered });
  };

  render() {
    const { isOpenAdd, editMode, listSynthesis, editedName, isButtonEnabled, isLoading, error } =
      this.state;
    const { beneficiary, consultant_id } = this.props;

    if (isLoading) {
      return (
        <div className="loading-container">
          <SpinnerCircular
            thickness={200}
            speed={150}
            color={Icon.color.Grey1}
            secondaryColor={Icon.color.Accent}
          />
          <p>Chargement en cours...</p>
        </div>
      );
    }

    if (error || !beneficiary) {
      return (
        <div className="error-container">
          {error || 'Le bénéficiaire est introuvable.'}
          <PrimaryButton
            id={'closeButton'}
            label={'OK'}
            onClick={() => this.setState({ error: null })}
          />
        </div>
      );
    }

    return (
      <ConsultantBeneficiary beneficiary={beneficiary}>
        {!editMode && (
          <>
            <div className="header-iasynthesis-page">
              <HeaderConsultantTab
                title={"Ma synthèse avec l'IA"}
                description={
                  "Générez votre synthèse avec notre assistant IA. Conçue pour respecter la synthèse d'accompagnement finale dans le cadre d'un bilan de compétences, elle peut être aussi utilisée pour tout type d'accompagnement pro."
                }
                illustration={HeaderIllustration}
              />
              <div className="beta">Bêta</div>
            </div>

            <div className="circle-button-wrapper">
              <CircleButton icon="plus" onClick={() => this.setOpenModal(true)} />
            </div>

            <Modal
              onClosePopin={() => this.setOpenModal(false)}
              openPopin={isOpenAdd}
              title={'Clarifiez certains éléments du projet'}
              content={
                <AISynthesisCreation
                  setOpenModal={this.setOpenModal}
                  beneficiary_id={beneficiary.profile_id}
                  consultant_id={consultant_id}
                />
              }
            />

            <DataTable2 data={listSynthesis} />
          </>
        )}

        {editMode && (
          <div className="wrapper-edit-page">
            {/* ACTIONS */}
            <div className="header-edit-page">
              <div className="wrapper-actions-button">
                <PrimaryButton
                  id={'finishedButton'}
                  label={'Terminé'}
                  disabled={!isButtonEnabled}
                  onClick={this.handleStatusSynthesis}
                />
                <PrimaryButton
                  id={'closeButton'}
                  label={'Fermer'}
                  outline={true}
                  onClick={() => this.setEditMode()}
                />
                <PrimaryButton
                  id={'saveButton'}
                  label={'Sauvegarder'}
                  disabled={!editedName.edited}
                  onClick={this.handleSaveSynthesis}
                />
              </div>
            </div>

            <div className="wrapper-content">
              {/* TITLE & STATUS */}
              <div className="wrapper-title-status">
                <AiSynthesisNameEdition name={editMode.name} onSave={this.updateSynthesisName} />
                <p className="status">{editMode.statusToDisplay}</p>
              </div>
              {/* DIRECTIVES */}
              <div className="presentation-card">
                <div className="form">
                  <div className="fields" style={{ flexDirection: 'row', gap: '0px' }}>
                    <div className="wrapper-directives">
                      <h2>Directives</h2>
                      {editMode.aiSynthesisDirectives.map((item) => (
                        <div
                          key={'directive-' + item.id}
                          style={{
                            textAlign: 'center',
                          }}
                        >
                          <span>{item.prompt}</span>
                          <p style={{ fontWeight: 'bold' }}>{item.answer}</p>
                        </div>
                      ))}
                    </div>
                    <div className="wrapper-datasources">
                      <h2>
                        Pièces jointes{' '}
                        {editMode.statusReason && (
                          <span
                            onMouseEnter={() => this.handleIsHovered(true)}
                            onMouseLeave={() => this.handleIsHovered(false)}
                            style={{ cursor: 'help' }}
                          >
                            ⚠️
                          </span>
                        )}
                      </h2>
                      {editMode.statusReason && this.state.isHovered && (
                        <p
                          style={{
                            textTransform: 'none',
                            position: 'absolute',
                            top: '30px',
                            left: '25%',
                            right: '25%',
                            display: 'flex',
                            justifyContent: 'center',
                            whiteSpace: 'wrap',
                            backgroundColor: 'var(--text-primary)',
                            borderRadius: '5px',
                            padding: '1px 5px',
                            minWidth: '100px',
                            color: 'white',
                            fontSize: '12px',
                            opacity: 0.75,
                            zIndex: 9999,
                          }}
                        >
                          {editMode.statusReason}
                        </p>
                      )}
                      {editMode.aiSynthesisAttachments.map((item) => (
                        <div
                          key={`attachment-${item.id}-${item.attachementId}`}
                          style={{
                            textAlign: 'center',
                          }}
                        >
                          <span>{item.filename}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              {/* SECTIONS */}
              {editMode.aiSynthesisSection.map((section) => (
                <AISynthesisSection
                  key={'section-' + section.sectionCode}
                  synthesis={editMode}
                  sectionId={section.id}
                  setOpenModal={this.setOpenModal}
                  handleUpdate={this.handleChildUpdate}
                />
              ))}
            </div>
          </div>
        )}
      </ConsultantBeneficiary>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConsultantBeneficiaryIASynthesisPage);
