import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import './DescriptionTextArea.scss';

export default class DescriptionTextArea extends PureComponent {
  static propTypes = {
    title: PropTypes.string,
    name: PropTypes.string,
    maxLength: PropTypes.number.isRequired,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    readonly: PropTypes.bool,
  }

  static defaultProps = {
    value: '',
    readonly: false,
    onChange: ({ id, value }) => undefined,
  }

  handleChange = (e) => {
    this.props.onChange({
      id: this.props.name,
      value: e.target.value,
    })
  }

  render() {
    const { id, value, placeholder, maxLength, readonly, disabled } = this.props

    return (
      <label className="description-text-area">
        <div className='common-form-label'>{this.props.title}</div>
        <textarea
          value={value}
          name={id}
          placeholder={placeholder}
          // maxLength={maxLength ? maxLength : undefined}
          onChange={this.handleChange}
          readOnly={readonly}
          disabled={disabled ? disabled : false}
        />
        <span className="remaining">
          {maxLength ? (value === null ? `0/${maxLength}` : `${value.length}/${maxLength}`) : ''}
        </span>
      </label>
    )
  }
}
