import moment from "moment";

export const removeAccents = (string) => {
    return string.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
};

export const normalizeString = (string) => {
    return removeAccents(string).toLowerCase();
};

export const containsSearchValue = (mainString, searchValue) => {
    const normalizedMainString = normalizeString(mainString);
    const normalizedSearchValue = normalizeString(searchValue);

    return normalizedMainString.includes(normalizedSearchValue);
};

export const cleanFileNameForURL = (fileName) => {
    const splitName = fileName
        .normalize('NFD') // Normalise les accents
        .replace(/[\u0300-\u036f]/g, '') // Supprime les diacritiques
        .replace(/[^\w.-]+/g, '-') // Remplace les caractères non valides par des tirets (y compris ’)
        .replace(/-+/g, '-') // Remplace les multiples tirets par un seul
        .replace(/^-+|-+$/g, '')
        .replace(' ', '_')
        .replace("'", '-')
        .toLowerCase().split('.'); // Convertit en minuscule pour uniformiser
    return (splitName[0] + '-' + moment().format('YYYY-MM-DD-HH-mm-ss') + '.' + splitName[1]);
};