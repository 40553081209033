import React, { Component } from 'react'
import { connect } from 'react-redux'
import CircleButton from '../../components/atoms/CircleButton/CircleButton'
import Icon from '../../components/atoms/Icon/Icon'
import ComboFilterField from '../../components/molecules/ComboFilterField/ComboFilterField'
import LineMenu from '../../components/organisms/LineMenu/LineMenu'
import AdminPageAside from '../../containers/admin/AdminPageAside/AdminPageAside'
import CourseTemplateList from '../../containers/course/CourseTemplateList/CourseTemplateList'
import Page from '../../containers/global/Page/Page'
import TopSearch from '../../containers/global/TopSearch/TopSearch'

import LeftAsideLayout from '../../layouts/LeftAsideLayout/LeftAsideLayout'
import TabbedContentLayout from '../../layouts/TabbedContentLayout/TabbedContentLayout'
import { actions as CourseTemplateActions } from '../../redux/CourseTemplateRedux'
import {
  actions as NavigationActions,
  selectors as NavigationSelectors,
} from '../../redux/NavigationRedux'

import "./AdminCourseTemplatesPage.scss";

const mapStateToProps = (state) => ({
  pathname: NavigationSelectors.pathname(state),
})

const mapDispatchToProps = (dispatch) => ({
  getTemplates: (filter) => dispatch(CourseTemplateActions.getTemplates(filter)),
  redirect: (pathname) => dispatch(NavigationActions.push(pathname)),
})

class AdminCourseTemplatesPage extends Component {
  state = {
    published: true,
    sort: '',
  }

  handleCreateClick = () => {
    this.props.redirect(`${this.props.pathname}/new`)
  }

  handleSortChange = ({ value: sort }) => {
    this.setState({ sort }, this.getTemplates)
  }

  getTemplates = (title) => {
    this.props.getTemplates({
      title,
      template_published: this.state.published,
    })
  }

  componentDidMount() {
    this.getTemplates()
  }

  renderContent() {
    const { sort, published } = this.state
    const filters = ["Nombre de candidats suivi"]
    const canAdd = published === false
    const options = Object.keys(filters).map((key) => ({
      value: key,
      label: filters[key],
    }))

    return (
      <>
        <div className="components">
          <CircleButton icon={Icon.icon.Plus} onClick={this.handleCreateClick} />

          <ComboFilterField
            id="sort"
            options={options}
            value={sort}
            title={"Classé par :"}
            onChange={this.handleSortChange}
          />
        </div>

        <CourseTemplateList />
      </>
    )
  }

  handleTabChange = (id) => {
    const published = id === 'published'

    this.setState({ published }, this.getTemplates)
  }

  renderSearchBar() {
    return <TopSearch onSearch={this.getTemplates} />
  }

  renderTabs() {
    const { published } = this.state
    const links = [
      { id: 'published', label: "Publiés" },
      { id: 'draft', label: "Non publiés" },
    ]

    return (
      <LineMenu
        value={published ? 'published' : 'draft'}
        links={links}
        onChange={this.handleTabChange}
        components={this.renderSearchBar()}
      />
    )
  }

  render() {
    const aside = <AdminPageAside />

    const content = (
      <TabbedContentLayout header={this.renderTabs()} content={this.renderContent()} />
    )

    return (
      <div className="admin-course-templates-page">
        <LeftAsideLayout aside={aside} content={content} />
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Page(AdminCourseTemplatesPage))