import React, { Component } from 'react'
import { connect } from 'react-redux'
import { isValid, reduxForm } from 'redux-form'
import Page from '../../containers/global/Page/Page'
import {
  actions as BeneficiaryActions,
  selectors as BeneficiarySelectors,
} from '../../redux/BeneficiaryRedux'

import {
  actions as BeneficiaryGroupActions,
  selectors as BeneficiaryGroupSelectors,
} from '../../redux/BeneficiaryGroupRedux'

import { selectors as WSSelectors } from '../../redux/WSRedux'
import { selectors as UserSelectors } from '../../redux/UserRedux'

import ConsultantBeneficiary from '../../containers/consultant/ConsultantBeneficiary/ConsultantBeneficiary'
import { BeneficiaryProfileContent } from '../../containers/beneficiary/BeneficiaryProfileContent/BeneficiaryProfileContent'
import ConsultantBeneficiaryAside from '../../containers/consultant/ConsultantBeneficiaryAside/ConsultantBeneficiaryAside'
import { asyncValidate } from '../../helpers/form/FormValidator/FormValidator'
import { getValidationRules, profileFieldsInCreation } from '../../helpers/beneficiaryProfileForm'

const mapStateToProps = (state) => ({
  saveAction: WSSelectors.action(state, 'beneficiary.saveBeneficiary'),
  valid: isValid('beneficiary-new')(state),
  beneficiary: BeneficiarySelectors.beneficiary(state),
  groups: BeneficiaryGroupSelectors.groups(state),
  user: UserSelectors.user(state),
})

const mapDispatchToProps = (dispatch) => ({
  setBeneficiary: (beneficiary) => dispatch(BeneficiaryActions.setBeneficiary(beneficiary)),
  getCurrentUserGroups: () => dispatch(BeneficiaryGroupActions.getCurrentUserGroups()),
  saveBeneficiary: (beneficiary) => dispatch(BeneficiaryActions.saveBeneficiary(beneficiary)),
})

class ConsultantBeneficiaryNewPage extends Component {

  handleChange = ({ value }) => {
    const newBeneficiary = [
      'gender',
      'image_url',
      'last_name',
      'first_name',
      'email',
      'consultant_id',
      'comments',
    ].reduce((res, prop) => (value[prop] != null ? { ...res, [prop]: value[prop] } : res), {})

    this.props.setBeneficiary(newBeneficiary)
  }

  renderContent = () => {
    return (
      <BeneficiaryProfileContent
        isNew={true}
      />
    )
  }

  renderAside = () => {
    return <ConsultantBeneficiaryAside newBeneficiary />
  }

  disableSave = () => {
    const { saveAction, beneficiary, valid, user } = this.props
    const { pending } = saveAction

    const mandatoryFields = ['gender', 'last_name', 'first_name', 'email']
    if (user.role === 'manager') {
      mandatoryFields.push('consultant_id')
    }
    return (
      pending ||
      mandatoryFields.reduce(
        (res, prop) => res || beneficiary[prop] == null || beneficiary[prop] === '',
        false,
      ) ||
      !valid
    )
  }

  handleSave = () => {
    const { beneficiary, saveBeneficiary } = this.props

    saveBeneficiary({ ...beneficiary })
  }

  componentDidMount() {
    this.props.setBeneficiary({})
    this.props.getCurrentUserGroups()
  }

  render() {
    const { beneficiary, groups } = this.props

    if (!beneficiary || !groups) {
      return null
    }

    return (
      <ConsultantBeneficiary
        renderAside={this.renderAside}
        disableSave={this.disableSave()}
        onSave={this.handleSave}
      >
        <form noValidate>
          <div className="consultant-beneficiary-new-page">{this.renderContent()}</div>
        </form>
      </ConsultantBeneficiary>
    )
  }
}

ConsultantBeneficiaryNewPage = reduxForm({
  form: 'beneficiary-new',
  asyncChangeFields: profileFieldsInCreation,
  asyncValidate: (values, dispatch, props) => {
    const validationRules = getValidationRules(
      props.beneficiary,
      values,
      props.user.role === 'consultant',
      true,
    )

    return asyncValidate(values, validationRules)
  },
})(ConsultantBeneficiaryNewPage)

export default connect(mapStateToProps, mapDispatchToProps)(Page(ConsultantBeneficiaryNewPage))