/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CircleButton from '../../components/atoms/CircleButton/CircleButton'
import Icon from '../../components/atoms/Icon/Icon'
import LineMenu from '../../components/organisms/LineMenu/LineMenu'
import ConsultantPageAside from '../../containers/consultant/ConsultantPageAside/ConsultantPageAside'
import CourseTemplateList from '../../containers/course/CourseTemplateList/CourseTemplateList'
import TopSearch from '../../containers/global/TopSearch/TopSearch'
import LeftAsideLayout from '../../layouts/LeftAsideLayout/LeftAsideLayout'
import TabbedContentLayout from '../../layouts/TabbedContentLayout/TabbedContentLayout'
import { actions as NavigationActions, selectors as NavigationSelectors } from '../../redux/NavigationRedux'
import { createCourse } from '../../api/CourseApi';
import "./ConsultantCourseTemplatesPage.scss"
import { selectors as UserSelectors } from "../../redux/UserRedux"
import { connect } from 'react-redux'
import { getTemplatesCourses, getTemplatesOfficeCourses, getTemplatesMyTalentsCourses } from '../../api/TemplateCourseApi'
const mapStateToProps = (state) => ({
  user: UserSelectors.user(state),
})

const ConsultantCourseTemplatesPage = ({ user }) => {
  const dispatch = useDispatch()
  const [scope, setScope] = useState('consultant')
  const [sort] = useState('')
  const pathname = useSelector(NavigationSelectors.pathname)
  const currentTab = useSelector((state) => NavigationSelectors.query(state, 'tab') || 'consultant')
  const [courses, setCourses] = useState();

  const redirect = useCallback(
    (destination) => {
      dispatch(NavigationActions.push(destination))
    },
    [dispatch],
  )

  const handleCreateClick = () => {
    const data = { consultant_id: user.profile_id, office_id: user.office.id };
    createCourse(data).then((response) => {
      redirect(`${pathname}/${response.course.id}`)
    })
  }

  const getConsultantCourses = () => {
    const { id } = user;
    getTemplatesCourses(id).then((response) => {
      setCourses(response.courses);
    })
  }
  const getOfficeCourses = () => {
    const { id } = user;
    getTemplatesOfficeCourses(id).then((response) => {
      setCourses(response.courses);
    })
  }
  const getMyTalentsCourses = () => {
    getTemplatesMyTalentsCourses().then((response) => {
      setCourses(response.courses);
    })
  }

  const getTemplates = useCallback(
    () => {
      if (scope === "mytalents") {
        getMyTalentsCourses();
      }
      else if (scope === "office") {
        getOfficeCourses();
      }
      else if (scope === "consultant") {
        getConsultantCourses();
      }
    }, [scope]
  )

  const handleTabChange = useCallback(
    (scope) => {
      setScope(scope)
      redirect(`${pathname}?tab=${scope}`)
    },
    [pathname, redirect],
  )


  useEffect(() => {
    getTemplates(sort)
  }, [getTemplates, sort])

  useEffect(() => {
    getTemplates()

    if (scope !== currentTab) {
      handleTabChange(currentTab)
    }
  }, [scope, currentTab, getTemplates, handleTabChange])

  const searchBar = () => <TopSearch onSearch={getTemplates} />

  const list = () => {
    const canAdd = scope === 'consultant'

    return (
      <>
        <div className="components">
          {canAdd && <CircleButton icon={Icon.icon.Plus} onClick={handleCreateClick} />}
        </div>

        <CourseTemplateList scope={scope} />
      </>
    )
  }

  const tabs = () => {
    const tabs = [
      { id: 'consultant', label: "Mes parcours" },
      { id: 'office', label: "Nos parcours" },
      { id: 'mytalents', label: "MyTalents" },
    ]

    return (
      <LineMenu value={scope} links={tabs} onChange={handleTabChange} components={searchBar()} />
    )
  }

  const aside = <ConsultantPageAside />

  const content = <TabbedContentLayout header={tabs()} content={list()} />

  return (
    <div className="page">
      <div className="consultant-course-templates-page">
        <LeftAsideLayout aside={aside} content={content} />
      </div>
    </div>
  )
}

export default connect(mapStateToProps, null)(ConsultantCourseTemplatesPage) 
