// import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import Icon from '../../components/atoms/Icon/Icon'
import PrimaryButton from '../../components/atoms/PrimaryButton/PrimaryButton'
import ActionPopinHeader from '../../components/molecules/ActionPopinHeader/ActionPopinHeader'
import DescriptionTextArea from '../../components/molecules/DescriptionTextArea/DescriptionTextArea'
import AlertPopin from '../../components/organisms/AlertPopin/AlertPopin'
import SideQuestionTypeSelector from '../../components/organisms/SideQuestionTypeSelector/SideQuestionTypeSelector'
import AsideResourceSelector from '../../containers/course/AsideResourceSelector/AsideResourceSelector'
import ModuleEditDragDropContext from '../../containers/course/ModuleEditDragDropContext/ModuleEditDragDropContext'
import ModuleTextEdit from '../../containers/course/ModuleTextEdit/ModuleTextEdit'
import Page from '../../containers/global/Page/Page'
import { Popin } from '../../containers/global/Popin/Popin'
import ModuleFormEdit from '../../containers/module/ModuleFormEdit/ModuleFormEdit'
import PopinLayout from '../../layouts/PopinLayout/PopinLayout'
import RightAsideLayout from '../../layouts/RightAsideLayout/RightAsideLayout'
import {
  actions as CourseTemplateActions,
  selectors as CourseTemplateSelectors,
} from '../../redux/CourseTemplateRedux'
import {
  actions as ModuleTemplateActions,
  selectors as ModuleTemplateSelectors,
} from '../../redux/ModuleTemplateRedux'
import {
  actions as NavigationActions,
  selectors as NavigationSelectors,
} from '../../redux/NavigationRedux'
import { actions as NotificationActions } from '../../redux/NotificationRedux'
import { selectors as ResourceTemplateSelectors } from '../../redux/ResourceTemplateRedux'
import { actions as WSActions, selectors as WSSelectors } from '../../redux/WSRedux'
import './ModuleEditPage.scss';

const mapStateToProps = (state, props) => ({
  id: props.match.params.module_id,
  pathname: NavigationSelectors.pathname(state),
  saveAction: WSSelectors.action(state, 'moduleTemplate.save'),
  course: CourseTemplateSelectors.template(state),
  template: ModuleTemplateSelectors.template(state),
  resources: ResourceTemplateSelectors.templates(state),
  hasChanged: ModuleTemplateSelectors.hasChanged(state),
})

const mapDispatchToProps = (dispatch) => ({
  back: (prefix = '') => dispatch(NavigationActions.back(prefix, prefix === '')),
  replace: (pathname) => dispatch(NavigationActions.replace(pathname)),
  getTemplate: (id) => dispatch(ModuleTemplateActions.getTemplate(id)),
  saveTemplate: (template) => dispatch(ModuleTemplateActions.saveTemplate(template)),
  saveCourseTemplate: (template = null) => dispatch(CourseTemplateActions.saveTemplate(template)),
  setTemplate: (template) => dispatch(ModuleTemplateActions.setTemplate(template)),
  updateTemplate: (template) => dispatch(ModuleTemplateActions.updateTemplate(template)),
  updateModuleAtIndex: (stepIndex, moduleIndex, template) =>
    dispatch(CourseTemplateActions.updateModuleAtIndex(stepIndex, moduleIndex, template)),
  addErrorNotification: (text) => dispatch(NotificationActions.add('error', text)),
  resetSave: () => dispatch(WSActions.reset('moduleTemplate.save')),
})

class ModuleEditPage extends Component {
  // static propTypes = {
  //   t: PropTypes.func.isRequired,
  //   saveCourseTemplate: PropTypes.func.isRequired,
  // }

  state = { confirmBack: false }

  handleBack = () => {
    if (this.props.course === null) {
      this.props.back()
    } else {
      this.props.back('/modules')
    }
  }

  handleChange = ({ id, value }) => {
    this.props.updateTemplate({ [id]: value })
  }

  handleSave = () => {
    const { template, addErrorNotification} = this.props
    const { title, type } = template

    const isEmpty =
      (type === 'text' && title === "Texte") ||
      (type === 'form' && title === "Formulaire") ||
      title === ''

    if (isEmpty) {
      return addErrorNotification("Le module est vide")
    }

    this.updateTemplate()
  }

  handleTogglePublish = () => {
    this.props.updateTemplate({ template_published: !this.props.template.template_published })
    this.forceUpdate(this.handleSave)
  }

  handleTitleChange = ({ id, value }) => {
    this.handleChange({ id: 'title', value })
  }

  updateTemplate() {
    const { course, template } = this.props
    const { stepIndex, moduleIndex } = template
    const hasIndexes = typeof stepIndex === 'number' && typeof moduleIndex === 'number'
    const toUpdate = { ...template }

    if (course && hasIndexes) {
      toUpdate.order = moduleIndex
      toUpdate.step_id = course.steps[stepIndex].id
    }

    if (!course || toUpdate.id) {
      this.props.saveTemplate(toUpdate)
    } else {
      if (hasIndexes) {
        this.props.updateModuleAtIndex(stepIndex, moduleIndex, template)
      }

      this.props.saveCourseTemplate()
    }

    this.props.setTemplate(toUpdate)
  }

  componentDidMount() {
    const { id, template, getTemplate } = this.props

    this.props.resetSave()

    if (!template) {
      ;/\d+/.test(id) ? getTemplate(id) : this.handleBack()
    }
  }

  componentDidUpdate() {
    const { id, course, template, pathname, saveAction } = this.props
    const hasTemplate = Boolean(template && template.id)
    const { resolved } = saveAction

    if (!course && resolved && hasTemplate && !/\d+/.test(id)) {
      this.props.replace(pathname.replace(/\w+$/, template.id))
    }
  }

  componentWillUnmount() {
    this.props.setTemplate(null)
  }

  handleConfirmPopinClick = () => {
    this.props.hasChanged
      ? this.setState(({ confirmBack }) => ({ confirmBack: !confirmBack }))
      : this.handleConfirmLeaveWithoutSave()
  }

  handleConfirmLeaveWithoutSave = () => {
    this.props.setTemplate(null)
    this.handleBack()
  }

  renderConfirmClose = () => {
    const {removing } = this.props

    return (
      <Popin onClose={this.handleConfirmPopinClick} open={this.state.confirmBack}>
        <AlertPopin
          disabled={removing}
          label={"Quitter sans sauvegarder ?"}
          text={"Voulez-vous quitter sans sauvegarder ? Vos données seront perdues"}
          labelCancelButton={"Non"}
          labelConfirmButton={"Oui"}
          onCancelButtonClick={this.handleConfirmPopinClick}
          onConfirmButtonClick={this.handleConfirmLeaveWithoutSave}
        />
      </Popin>
    )
  }

  renderHeader() {
    const { id, saving, template } = this.props
    const { template_published, title, type } = template

    let disabled = saving || title === ''

    let finalTitle = title
    let placeholderTitle = ''
    if (type === 'text') {
      placeholderTitle = "Cliquez ici pour renseigner le titre du bloc de ressources"
      if (title === "Texte") {
        disabled = true
        finalTitle = placeholderTitle
      }
    }
    if (type === 'form') {
      placeholderTitle = "Cliquez ici pour renseigner le titre du formulaire"
      if (title === "Formulaire") {
        disabled = true
        finalTitle = placeholderTitle
      }
    }

    if (title === '') {
      finalTitle = placeholderTitle
    }

    return (
      <ActionPopinHeader
        iconButton={Icon.icon.Back}
        boldTitle={finalTitle}
        disabled={disabled}
        editable
        onTitleChange={this.handleTitleChange}
        onIconClick={this.handleConfirmPopinClick}
      >
        {/\d+/.test(id) && (
          <PrimaryButton
            label={template_published ? "Dé-publier" : "Publier"}
            id="publish"
            disabled={disabled}
            icon={Icon.icon.Publish}
            onClick={this.handleTogglePublish}
          />
        )}

        <PrimaryButton label={"Sauvegarder"} id="save" onClick={this.handleSave} />
      </ActionPopinHeader>
    )
  }

  getModuleHeaderLabel() {
    const { template } = this.props
    const { type, data } = template
    const { questions = [], resources = [] } = data

    switch (type) {
      case 'text':
        if(resources.length > 1) return `${resources.length} ressources`
        else return `${resources.length} ressource`
      case 'form':
        if(questions.length > 1) return `${questions.length} questions`
        else return `${questions.length} question`
      default:
        throw new Error(`Incompatible type: "${type}"`)
    }
  }

  renderModuleData() {
    const { template } = this.props
    const { type } = template

    switch (type) {
      case 'text':
        return <ModuleTextEdit />

      case 'form':
        return <ModuleFormEdit />

      default:
        throw new Error(`Incompatible type: "${type}"`)
    }
  }

  renderContent() {
    const { template } = this.props
    const { description } = template

    return (
      <>
        <div
          className="count-resources"
          dangerouslySetInnerHTML={{ __html: this.getModuleHeaderLabel() }}
        />

        <div className="description">
          <DescriptionTextArea
            // maxLength={500}
            name="description"
            placeholder={"Description du module"}
            onChange={this.handleChange}
            value={description}
          />
        </div>

        {this.renderModuleData()}
        {this.renderConfirmClose()}
      </>
    )
  }

  renderAside() {
    const { template } = this.props
    const { type } = template

    switch (type) {
      case 'text':
        return <AsideResourceSelector />

      case 'form':
        const questionTypesLocales = [
          {id: "1", name: "text", label: "Texte"} , 
          {id: "2", name: "select", label: "Liste déroulante"},
          {id: "3", name: "multiple_choice", label: "Choix multiples"},
          {id: "4", name: "group", label: "Groupe de question"},
          {id: "5", name: "yes_no", label: "Oui / Non"},
          {id: "6", name: "rating", label: "Evaluation"},
          {id: "7", name: "opinion_scale", label: "Echelle de mesure"},
          {id: "8", name: "date", label: "Date"},
        ]
        return (
          <SideQuestionTypeSelector
            title={"Boite à outils"}
            questionTypes={questionTypesLocales.map(qTypeKey => ({
              id: qTypeKey.id, // ici à voir probleme /  old : id: `question-types-list-${qTypeKey}`,
              label: qTypeKey.label,
              icon: qTypeKey.name,
              type: qTypeKey.name
            }))}
            // questionTypes={Object.keys(questionTypesLocales).map((qTypeKey) => ({
            //   id: `question-types-list-${qTypeKey}`,
            //   label: questionTypesLocales[qTypeKey],
            //   icon: qTypeKey,
            //   type: qTypeKey,
            // }))}
          />
        )

      default:
        throw new Error(`Incompatible type: "${type}"`)
    }
  }

  render() {
    const { template } = this.props

    if (!template) {
      return null
    }

    const header = this.renderHeader()
    const content = <RightAsideLayout content={this.renderContent()} aside={this.renderAside()} />

    return (
      <ModuleEditDragDropContext>
        <div className="module-edit-page">
          <PopinLayout header={header} content={content} />
        </div>
      </ModuleEditDragDropContext>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Page(ModuleEditPage))