import React, { useEffect, useState } from "react";
import Icon from "../atoms/Icon/Icon";
import IconButton from "../atoms/IconButton/IconButton";

const MessageItem = ({ message, type, onDeleteMessage }) => {
    const [visioLink, setVisioLink] = useState("");
    const [visioMessage, setVisioMessage] = useState("");

    useEffect(() => {
        // Séparer le message de visio pour récupérer le lien et le rendre cliquable
        if (message.type === "visio") {
            const string = message.content;
            const startIndex = string.indexOf("http");
            setVisioMessage(startIndex !== -1 ? string.substring(0, startIndex) : "");
            setVisioLink(startIndex !== -1 ? string.substring(startIndex) : "");
        }
    }, [message]);

    return (
        <>
            <div className={`message message-${type}`}>
                <div className="message-infos">
                    <p>{message.author_name}&nbsp;:</p>
                    <p className="message-date"><img src={`../static/icons/clock-${type}.svg`} alt="clock" />{message.date_emitted.slice(0, -3)}</p>
                    {type === "in" &&
                        <IconButton
                            icon={Icon.icon.Close}
                            color="white"
                            onClick={onDeleteMessage}
                            tooltip="Supprimer"
                        />
                    }
                </div>

                {message.type === "text" || message.status === "sending" ? (
                    <p className={`message-content message-content-${type}`}>{message.content}</p>
                ) : message.type === "image" ? (
                    <div className={`div-message-image message-content message-content-${type}`}>
                        <img src={message.content} alt="Problème lors du chargement du fichier" />
                    </div>
                ) : message.type === "visio" ? (
                    <p className={`message-content message-content-${type}`}>{visioMessage}<a href={visioLink} target="_blank">{visioLink}</a></p>
                ) : (
                    <p className={`message-content message-content-${type}`}><a href={message.content} download>Télécharger le fichier <span className={message.type === "" ? "d-none" : ""}>.{message.type}</span></a></p>
                )}
            </div>
        </>
    )
}

export default MessageItem;