import 'bootstrap/dist/css/bootstrap-grid.min.css';
import ConversationList from "./ConversationList";
import ChatInput from "./ChatInput";
import ActiveConversation from "./ActiveConversation";
import MessageList from "./MessageList";
import React, { useEffect, useState } from "react";
import Icon from '../atoms/Icon/Icon';

import { connect } from "react-redux";
import { selectors as UserSelectors } from "../../redux/UserRedux";
import { syncRooms, deleteMessage, getMessagesInRoom, sendMessage } from '../../api/ChatApi';

const mapStateToProps = (state) => ({
  user: UserSelectors.user(state),
})

const ChatContainer = (props) => {
  const [activeConversation, setActiveConversation] = useState(null);
  const [activeConversationName, setActiveConversationName] = useState("");
  const [messages, setMessages] = useState([]);
  const [conversations, setConversations] = useState([]);

  const syncConversations = async (userId, userRole) => {
    const rooms = await syncRooms(userId, userRole)
    setConversations(rooms)
  }

  useEffect(() => {
    syncConversations(props.user.id, props.user.role);
  }, [props.user.id, props.user.role])

  useEffect(() => {
    if (activeConversation) {
      const retrieveMessages = async (roomKey) => {
        const messages = await getMessagesInRoom(roomKey)
        setMessages(messages);
      }

      retrieveMessages(activeConversation.room_key)
    }

  }, [activeConversation])

  const handleSendMessage = async (message, type = "text") => {
    const tempMessage = {
      ...message,
      id: `temp-${Date.now()}`,
      content: type === "file" ? "Le fichier est en cours d'enregistrement." : message.content,
      status: 'sending'
    };

    setMessages((prevMessages) => [...prevMessages, tempMessage]);

    const sentMessage = await sendMessage(message);

    setMessages((prevMessages) =>
      prevMessages.map((msg) =>
        msg.id === tempMessage.id ? sentMessage : msg
      )
    );

    setLastMessageInConversation(sentMessage, type, false);
  };

  const setLastMessageInConversation = (message, type = "text", deleted = false) => {
    setConversations((prevConversations) =>
      prevConversations.map((conversation) =>
        conversation.room_key === message.room_key
          ? { ...conversation, last_message: message.content, last_message_type: type, last_message_is_deleted: deleted }
          : conversation
      )
    );
  }

  const handleDeleteMessage = (messageToDelete) => {
    const idMessage = messageToDelete.id
    const updatedMessages = messages.filter(message =>
      message.random_key !== messageToDelete.random_key && message.id !== messageToDelete.id
    )

    setMessages(updatedMessages)
    setLastMessageInConversation(messageToDelete, messageToDelete.type, true)

    deleteMessage(idMessage, messageToDelete.random_key, messageToDelete.room_key)
  }

  return (
    <>
      <div className="container chat-container mt-2 mb-2">
        <ConversationList
          conversations={conversations}
          setActiveConversation={setActiveConversation}
          setActiveConversationName={setActiveConversationName}
          idUser={props.user.id} />
        {activeConversation === null ?
          <>
            <div className="container container-full-conversation">
              <div className="no-conversation-selected">
                <Icon icon={Icon.icon.Mail} />
                <p>Sélectionnez une<br />conversation</p>
              </div>
            </div>
          </>
          :
          <>
            <div className="container container-full-conversation">
              <ActiveConversation
                activeConversation={activeConversation}
                activeConversationName={activeConversationName}
                handleSendMessage={handleSendMessage}
                idUser={props.user.id}
              />
              <MessageList
                messages={messages}
                idUser={props.user.id}
                handleDeleteMessage={handleDeleteMessage}
              />
              <ChatInput
                activeConversation={activeConversation}
                handleSendMessage={handleSendMessage}
                idUser={props.user.id}
              />
            </div>
          </>
        }
      </div>
    </>
  )
}

export default connect(mapStateToProps)(ChatContainer);