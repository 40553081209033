import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux'
import { selectors as UserSelectors } from '../../redux/UserRedux'

import { deleteGroup, getConsultantGroups } from "../../api/groupsApi";
import { getAllConsultants } from "../../api/ConsultantApi";
import { deleteTalentMission } from "../../api/MissionsApi";

import BigCombo from "../../components/molecules/BigCombo/BigCombo";
import CircleButton from "../../components/atoms/CircleButton/CircleButton";
import ConsultantPageAside from "../../containers/consultant/ConsultantPageAside/ConsultantPageAside";
import { GroupsList } from "../../containers/groups/GroupsList/GroupsList";
import LeftAsideLayout from "../../layouts/LeftAsideLayout/LeftAsideLayout";
import ManagerPageAside from "../../containers/manager/ManagerPageAside/ManagerPageAside";
import SearchField from "../../components/molecules/SearchField/SearchField";
import { Toaster } from "../../components/atoms/Toaster/Toaster";

import { useNavigation } from "../../hooks/useNavigation";
import { useToaster } from "../../hooks/useToaster";

import './ConsultantMyGroupsPage.scss';
import { containsSearchValue } from "../../api/utils/StringUtils";

export const ConsultantMyGroupsPage = () => {
    const user = useSelector(UserSelectors.user);
    const { pathname, redirect } = useNavigation();
    const [allGroups, setAllGroups] = useState([])
    const [groups, setGroups] = useState([]);
    const [query, setQuery] = useState("");
    const [consultants, setConsultants] = useState([]);
    // const location = useLocation();

    // const newGroup = location.state ? location.state.newMission : null;

    const { toasters, addToaster, removeToaster } = useToaster();

    // use effects 
    useEffect(() => {
        getConsultantGroups(user.id).then((response) => {
            const consultantGroups = response.groups
            setGroups(consultantGroups);
            setAllGroups(consultantGroups);
        })

        getConsultants();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getConsultants = () => {
        getAllConsultants(user.office_id).then((response) => {
            const all_consultants = response.consultants.map((c) => {
                return {
                    value: c.user.id,
                    label: `${c.user.first_name} ${c.user.last_name}`
                };
            });

            const all = { value: "all", label: "Tous" }

            all_consultants.push(all)
            setConsultants(all_consultants)
        })
    }

    const handleAddNew = () => {
        redirect(`${pathname}/new`)
    }

    const handleDeleteGroup = (group) => {
        const filterGroups = groups.filter(g => g.id !== group.id);
        setGroups(filterGroups);
        deleteGroup(group.id)
            .then(() => {
                addToaster("Groupe supprimé", `Le groupe ${group.name} a été supprimé avec succès`, "success")
            })
            .catch(() => {
                addToaster("Erreur", `Une erreur est survenue lors de la suppression du groupe ${group.name}`, "error")
            });
        if (group.group_users && group.mission_id) {
            const talents = group.group_users;
            talents.map(talent => deleteTalentMission(group.mission_id, talent.id))
        }
    }

    const handleOnSearch = (search) => {
        const searchValue = search.value;
        setQuery(searchValue);
        const filteredGroups = allGroups.filter(group => containsSearchValue(group.name, searchValue));
        setGroups(filteredGroups);
    }

    const handleFilterOnChangeConsultant = (dropdown) => {
        const { value } = dropdown;
        if (value.value !== "all") {
            const filteredGroups = allGroups.filter(group => group.user_id === value.value);
            setGroups(filteredGroups);
        }
        else setGroups(allGroups);
    }

    // const handleExportGroup = () => { }

    // renders components
    const renderContent = () => {
        return (
            <div className="consultant-my-groups-content">
                <div className="consultant-my-groups-header">
                    <CircleButton icon={"plus"} onClick={handleAddNew} />
                    {/* <PrimaryButton label={"Exporter"} onClick={handleExportGroup} /> */}
                </div>

                <div className="consultant-my-groups-filters">
                    {user.role === "manager" &&
                        <BigCombo title={""} options={consultants} value={""} onChange={handleFilterOnChangeConsultant} />
                    }
                    <SearchField placeholder={"Rechercher par nom de groupe"} value={query} onChange={handleOnSearch} />

                </div>
                {(groups && groups.length > 0)

                    ? <GroupsList groups={groups} onDeleteGroup={handleDeleteGroup} />
                    : <p>Aucun groupe...</p>
                }
            </div>
        )
    }

    const aside = user.role === "consultant" ? <ConsultantPageAside /> : <ManagerPageAside />

    return (
        <div className="page">
            <div className="consultant-my-groups-page">
                <LeftAsideLayout aside={aside} content={renderContent()} />
            </div>
            <div className="notification-list">
                {toasters.map(toaster => <Toaster key={toaster.id} title={toaster.title} message={toaster.message} type={toaster.type} onClose={() => removeToaster(toaster.id)} />)}
            </div>
        </div>
    )
}