import React, { useEffect, useState } from "react";

import { NewTag } from "../../atoms/NewTag/NewTag";
import Icon from "../../atoms/Icon/Icon";
import { DeleteGroupPopin } from "../../../containers/groups/DeleteGroupPopin/DeleteGroupPopin";

import { useNavigation } from "../../../hooks/useNavigation";

import './GroupCard.scss';
import { getConsultantInfos } from "../../../api/ConsultantApi";

export const GroupCard = ({ group, onDeleteGroup }) => {
    const { pathname, redirect } = useNavigation();
    const [showModalDeleteGroup, setShowModalDeleteGroup] = useState(false);
    const [consultant, setConsultant] = useState({ first_name: "", last_name: "" })

    useEffect(() => {
        getNameConsultant();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [group])


    const getNameConsultant = () => {
        const id = group.user_id;
        getConsultantInfos(id).then((response) => {
            const consultant = response.consultant.user;
            setConsultant({ first_name: consultant.first_name, last_name: consultant.last_name })
        })
    }

    const renderAvatarGroup = (image) => {
        if (image) {
            return <img src={image} alt="avatar-group" />
        }

        return (
            <div className="avatar-group">
                <Icon icon={'people'} color={"accent"} />
            </div>
        )
    }

    const renderAvatarMember = (image, userId) => {
        if (image) return <img key={userId} className="avatar-member-in-group" src={image} alt="avatar-group" />
        return (
            <div key={userId} className="avatar-member-in-group">
                <Icon icon="person" color={"accent"} />
            </div>
        )
    }

    const { name, group_users, image_url } = group
    const maxMembersAvatar = 4;
    const totalMembers = group_users?.length;
    const totalMembersVisibles = group_users?.slice(0, maxMembersAvatar);
    const remainingMembers = Math.max(0, totalMembers - maxMembersAvatar);

    return (
        <div className="group-card">
            <div className="group-card-header">
                <NewTag title={"En cours"} />
            </div>
            <div className="group-card-presentation-group" onClick={() => redirect(`${pathname}/${group.id}`)}>
                <div className="group-card-presentation-group-name">
                    {renderAvatarGroup(image_url)}
                    <h1>{name}</h1>
                </div>
                <div className="group-card-members-in-group">
                    {(group.group_users && group.group_users.length > 0)
                        ? <>
                            <p>{group.group_users.length} {group.group_users.length === 1 ? "personne" : "personnes"}</p>
                            <div className="avatars-members-in-group">
                                {totalMembersVisibles.map((user) => (
                                    renderAvatarMember(`${process.env.REACT_APP_ASSETS_API_STATIC_URL}${user.image_url ? (user.image_url).replace(process.env.REACT_APP_OLD_UPLOADS_API_STATIC_URL, '') : ""}`, user.id)
                                ))}
                                {remainingMembers > 0 &&
                                    <div className="avatar-more-members">
                                        <label>+{remainingMembers}</label>
                                    </div>
                                }
                            </div></>

                        : <p>Aucun talent ajouté</p>

                    }

                </div>
                <p>Animé par {consultant.first_name} {consultant.last_name}</p>
            </div>
            <div className="group-card-content">
                {/* <div>
                    <label>Durée</label>
                    <p>4 heures</p>
                </div>
                <div>
                    <label>Type d'accompagnement</label>
                    <p>Atelier collectif</p>
                </div> */}
                <div>
                    <label>Consultant</label>
                    <p>{consultant.first_name} {consultant.last_name}</p>
                </div>
            </div>
            <div className="group-card-delete" onClick={() => setShowModalDeleteGroup(true)}>
                <p>Supprimer le groupe</p>
            </div>
            {showModalDeleteGroup && <DeleteGroupPopin group={group} open={showModalDeleteGroup} onClose={() => setShowModalDeleteGroup(false)} onDeleteGroup={() => { onDeleteGroup(group); setShowModalDeleteGroup(false) }} />}
        </div>
    )
}