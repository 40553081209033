import { applyMiddleware, combineReducers, compose, createStore as createReduxStore } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { reducer as formReducer } from 'redux-form'
import { i18nReducer, syncTranslationWithStore } from 'react-redux-i18n'
import { connectRouter, routerMiddleware } from 'connected-react-router'

import { reducer as appReducer } from './AppRedux'
import { reducer as actionsReducer } from './WSRedux'
import { reducer as appointmentReducer } from './AppointmentRedux'
import { reducer as beneficiaryReducer } from './BeneficiaryRedux'
import { reducer as beneficiaryGroupsReducer } from './BeneficiaryGroupRedux'
import { reducer as consultantReducer } from './ConsultantRedux'
import { reducer as contactReducer } from './ContactRedux'
import { reducer as courseReducer } from './CourseRedux'
import { reducer as courseTemplateReducer } from './CourseTemplateRedux'
import { reducer as impersonateReducer } from './ImpersonateRedux'
import { reducer as localeReducer } from './LocaleRedux'
import { reducer as messageReducer } from './MessageRedux'
import { reducer as moduleReducer } from './ModuleRedux'
import { reducer as moduleTemplateReducer } from './ModuleTemplateRedux'
import { reducer as navigationReducer } from './NavigationRedux'
import { reducer as notesReducer } from './NotesRedux'
import { reducer as notificationReducer } from './NotificationRedux'
import { reducer as resourceTemplateReducer } from './ResourceTemplateRedux'
import { reducer as officeReducer } from './OfficeRedux'
import { reducer as statsReducer } from './StatsRedux'
import { reducer as uploadReducer } from './UploadRedux'
import { reducer as userReducer } from './UserRedux'
import { reducer as videoCallReducer } from './VideocallRedux'
import { reducer as centralTestReducer } from './CentralTestRedux'
import { reducer as portalReducer } from './PortalRedux'
import { reducer as colorThemeReducer } from './ColorThemeRedux';

import rootSaga from '../sagas'
import { history, setStore } from '../navigation/History'

export const sagaMiddleware = createSagaMiddleware()

export const createStore = () => {
  const reducer = combineReducers({
    actions: actionsReducer,
    app: appReducer,
    appointment: appointmentReducer,
    beneficiary: beneficiaryReducer,
    beneficiaryGroups: beneficiaryGroupsReducer,
    consultant: consultantReducer,
    contact: contactReducer,
    course: courseReducer,
    courseTemplate: courseTemplateReducer,
    impersonate: impersonateReducer,
    locale: localeReducer,
    message: messageReducer,
    module: moduleReducer,
    moduleTemplate: moduleTemplateReducer,
    navigation: navigationReducer,
    notes: notesReducer,
    notification: notificationReducer,
    office: officeReducer,
    resourceTemplate: resourceTemplateReducer,
    stats: statsReducer,
    upload: uploadReducer,
    user: userReducer,
    form: formReducer,
    i18n: i18nReducer,
    router: connectRouter(history),
    videocall: videoCallReducer,
    centraltest: centralTestReducer,
    portal: portalReducer,
    colorTheme: colorThemeReducer
  })

  const enhancer = [applyMiddleware(sagaMiddleware, routerMiddleware(history))]

  if (process.env.NODE_ENV !== 'production') {
    if (window.__REDUX_DEVTOOLS_EXTENSION__) {
      enhancer.push(window.__REDUX_DEVTOOLS_EXTENSION__())
    }
  }

  const store = createReduxStore(reducer, compose(...enhancer))

  syncTranslationWithStore(store)
  setStore(store)

  sagaMiddleware.run(rootSaga)

  return store
}
