import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import Icon from '../../components/atoms/Icon/Icon'
import PrimaryButton from '../../components/atoms/PrimaryButton/PrimaryButton'
import ActionPopinHeader from '../../components/molecules/ActionPopinHeader/ActionPopinHeader'
import IconCardTitle from '../../components/molecules/IconCardTitle/IconCardTitle'
import DescriptionTextArea from '../../components/molecules/DescriptionTextArea/DescriptionTextArea'
import RightAsideLayout from '../../layouts/RightAsideLayout/RightAsideLayout'
import AlertPopin from '../../components/organisms/AlertPopin/AlertPopin'
import { Popin } from '../../containers/global/Popin/Popin'
 
import PopinLayout from '../../layouts/PopinLayout/PopinLayout'
import {
  actions as CourseTemplateActions,
  selectors as CourseTemplateSelectors,
} from '../../redux/CourseTemplateRedux'
import { actions as ModuleActions } from '../../redux/ModuleRedux'
import {
  actions as ModuleTemplateActions,
  selectors as ModuleTemplateSelectors,
} from '../../redux/ModuleTemplateRedux'
import {
  actions as NavigationActions,
  selectors as NavigationSelectors,
} from '../../redux/NavigationRedux'
import { selectors as UserSelectors } from '../../redux/UserRedux'
import { reduxForm } from 'redux-form'
import NotesArea from '../../components/molecules/NotesArea/NotesAreaContainer'
import "./ModuleSynthesisEditPage.scss";

const mapStateToProps = (state) => ({
  isBeneficiary: UserSelectors.isBeneficiary(state),
  course: CourseTemplateSelectors.template(state),
  template: ModuleTemplateSelectors.template(state),
  hasChanged: ModuleTemplateSelectors.hasChanged(state),
  pathname: NavigationSelectors.pathname(state),
})

const mapDispatchToProps = (dispatch) => ({
  back: (prefix = '') => dispatch(NavigationActions.back(prefix, prefix === '')),
  saveSynthesisNotes: (template, notes) =>
    dispatch(ModuleActions.saveSynthesisNotes({ template, notes })),
  updateModuleAtIndex: (stepIndex, moduleIndex, module) =>
    dispatch(CourseTemplateActions.updateModuleAtIndex(stepIndex, moduleIndex, module)),
  setTemplate: (template) => dispatch(ModuleTemplateActions.setTemplate(template)),
  updateTemplate: (template) => dispatch(ModuleTemplateActions.updateTemplate(template)),
  saveTemplate: (template) => dispatch(ModuleTemplateActions.saveTemplate(template)),
  saveCourseTemplate: (template = null) => dispatch(CourseTemplateActions.saveTemplate(template)),
  replace: (pathname) => dispatch(NavigationActions.replace(pathname)),
})

class ModuleSynthesisEditPage extends Component {
  static propTypes = {
    saveCourseTemplate: PropTypes.func.isRequired,
    setTemplate: PropTypes.func.isRequired,
  }

  state = {
    beneficiary_notes: '',
    confirmBack: false,
  }

  handleBack = () => {
    this.props.back('/modules')
  }

  handleSave = () => {
    const { course, template, updateModuleAtIndex, saveSynthesisNotes, isBeneficiary } = this.props
    const { stepIndex, moduleIndex } = template

    if (isBeneficiary) {
      saveSynthesisNotes(
        {
          ...template,
          description: undefined,
        },
        this.state.beneficiary_notes,
      )
    } else {
      if (!course || template.id) {
        this.props.saveTemplate(template)
      } else {
        if (typeof stepIndex === 'number' && typeof moduleIndex === 'number') {
          this.props.updateModuleAtIndex(stepIndex, moduleIndex, template)
        }

        this.props.saveCourseTemplate()
      }

      updateModuleAtIndex(stepIndex, moduleIndex, template)
      this.props.setTemplate(template)
    }
  }

  checkForChanges = () => {
    const { template, isBeneficiary, hasChanged } = this.props

    return isBeneficiary
      ? template.data.beneficiary_notes === this.state.beneficiary_notes
      : !hasChanged
  }

  handleTitleChange = ({ value }) => {
    this.props.updateTemplate({ title: value })
  }

  handleSynthesisChange = ({ value }, isPrivate = false) => {
    const { template, isBeneficiary } = this.props
    const { data } = template

    if (!isBeneficiary) {
      let payload = {
        ...data,
        consultant_notes: value
      }
      if (isPrivate) {
        payload = {
          ...data,
          consultant_private_notes: value
        }
      }

      return this.props.updateTemplate({
        data: payload,
      })
    }

    this.setState({ beneficiary_notes: value })
  }

  handleDescriptionChange = ({ value }) => {
    return this.props.updateTemplate({
      description: value,
    })
  }

  handleIsPrivateToggleChange = ({ value }) => {
    const { template } = this.props
    const { data } = template

    return this.props.updateTemplate({
      data: { ...data, is_private: !data.is_private },
    })
  }

  handleCloseClick = () => {
    this.props.hasChanged
      ? this.setState(({ confirmBack }) => ({ confirmBack: !confirmBack }))
      : this.handleConfirmLeaveWithoutSave()
  }

  handleConfirmLeaveWithoutSave = () => {
    this.handleBack()
  }

  componentDidMount() {
    const { template, isBeneficiary, back } = this.props

    if (!template) {
      return back()
    }

    if (isBeneficiary) {
      this.setState({ beneficiary_notes: template.data.beneficiary_notes })
    }
  }

  componentWillUnmount() {
    this.props.setTemplate(null)
  }

  renderHeader = () => {
    const { isBeneficiary, template } = this.props

    return (
      <ActionPopinHeader
        iconButton={Icon.icon.Back}
        boldTitle={template ? template.title : "La synthèse"}
        editable={!isBeneficiary}
        onIconClick={this.handleCloseClick}
        onTitleChange={this.handleTitleChange}
      >
        <PrimaryButton
          id="save"
          label={"Sauvegarder"}
          disabled={this.checkForChanges()}
          onClick={this.handleSave}
        />
      </ActionPopinHeader>
    )
  }

  renderContent() {
    const { template, isBeneficiary } = this.props
    const value = isBeneficiary ? this.state.beneficiary_notes : template.data.consultant_notes
    const { description } = template
    const private_notes = !isBeneficiary ? template.data.consultant_private_notes : null

    return (
      <>
        <div className="description">
          {isBeneficiary && <p>{description}</p>}
          {!isBeneficiary && (
            <>
              <DescriptionTextArea
                // maxLength={500}
                name="description"
                value={description}
                placeholder={"Description"}
                onChange={this.handleDescriptionChange}
              />
            </>
          )}
        </div>
        {isBeneficiary && <p>{this.state.consultant_notes}</p>}
        <NotesArea
          onChange={({ value }) => this.handleSynthesisChange({ value })}
          title={"Ma synthèse"}
          placeholder={"Ecrivez votre synthèse d'étape ici..."}
          name="public_notes"
          value={value}
        />
        {!isBeneficiary && <NotesArea
          onChange={({ value }) => this.handleSynthesisChange({ value }, true)}
          isPrivate
          title={"Ma synthèse privée"}
          placeholder={"Ecrivez vos notes ici..."}
          name="private_notes"
          value={private_notes}
        />}
      </>
    )
  }

  renderAside() {
    const { template, isBeneficiary } = this.props

    if (isBeneficiary) {
      return null
    }

    return (
      <div className="side-synthesis">
        <IconCardTitle icon={Icon.icon.Edit} label={"Synthèse du talent"} />
        <div className="separator" />
        <div className="side-text">{template.data.beneficiary_notes}</div>
      </div>
    )
  }

  render() {
    if (!this.props.template) {
      return null
    }

    const header = this.renderHeader()
    const content = <RightAsideLayout content={this.renderContent()} aside={this.renderAside()} />

    return (
      <div className="module-synthesis-edit-page">
        <PopinLayout header={header} content={content} />

        <Popin onClose={this.handleCloseClick} open={this.state.confirmBack}>
          <AlertPopin
            label={"Quitter sans sauvegarder ?"}
            text={"Voulez-vous quitter sans sauvegarder ? Vos données seront perdues"}
            labelCancelButton={"Non"}
            labelConfirmButton={"Oui"}
            onCancelButtonClick={this.handleCloseClick}
            onConfirmButtonClick={this.handleConfirmLeaveWithoutSave}
          />
        </Popin>
      </div>
    )
  }
}

ModuleSynthesisEditPage = reduxForm({
  form: 'module-synthese',
})(ModuleSynthesisEditPage)

export default connect(mapStateToProps, mapDispatchToProps)(ModuleSynthesisEditPage)