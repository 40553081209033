import React, { useEffect, useState, useRef, useCallback } from "react";
import ConversationItem from "./ConversationItem";
import Icon from "../atoms/Icon/Icon";
import { debounce } from 'lodash';
import { containsSearchValue } from "../../api/utils/StringUtils";

const ConversationList = ({ conversations, setActiveConversation, setActiveConversationName, idUser }) => {
    const [sortedConversations, setSortedConversations] = useState([])
    const conversationRef = useRef();
    const conversationMobileRef = useRef();

    const handleClick = (e) => {
        const conversationContainerList = document.getElementsByClassName("conversation-container")[0];
        const conversationListMobile = document.getElementsByClassName("conversation-container-mobile")[0];

        conversationContainerList.classList.add("conversation-mobile");
        conversationListMobile.classList.add("conversation-mobile");
    }

    const handleOutsideClick = useCallback((e) => {
        if (conversationRef.current && !conversationRef.current.contains(e.target) && conversationMobileRef.current && !conversationMobileRef.current.contains(e.target)) {
            const conversationContainerList = document.getElementsByClassName("conversation-container")[0];
            const conversationListMobile = document.getElementsByClassName("conversation-container-mobile")[0];

            conversationContainerList.classList.remove("conversation-mobile");
            conversationListMobile.classList.remove("conversation-mobile");
        }
    }, [])

    useEffect(() => {
        document.addEventListener("click", handleOutsideClick);

        return () => {
            document.removeEventListener("click", handleOutsideClick);
        };
    }, [handleOutsideClick])

    const handleClose = () => {
        const conversationContainerList = document.getElementsByClassName("conversation-container")[0];
        const conversationListMobile = document.getElementsByClassName("conversation-container-mobile")[0];

        conversationContainerList.classList.remove("conversation-mobile");
        conversationListMobile.classList.remove("conversation-mobile");
    }

    const handleSearchKeyUp = (event) => {
        event.preventDefault()
        const searchValue = event.target.value
        debounceSearch(searchValue, conversations)
    }

    const debounceSearch = useCallback(
        debounce((searchValue, conversations) => {
            search(searchValue, conversations);
        }, 500),
    );

    const search = (searchValue, conversations) => {
        if (searchValue === "") {
            setSortedConversations(conversations);
            return;
        }

        const updatedConversations = conversations.map(conversation => {
            return {
                ...conversation,
                name: getConversationName(conversation)
            }
        })

        const matchedResults = []
        const unmatchedResults = []

        updatedConversations.forEach((conversation) => {
            if (conversation.name === null) {
                unmatchedResults.push(conversation)
            } else {
                (containsSearchValue(conversation.name, searchValue) ? matchedResults : unmatchedResults).push(conversation);
            }
        })

        const sortedResults = [...matchedResults, ...unmatchedResults];

        setSortedConversations(sortedResults);
    }

    const getConversationName = (conversation) => {
        const parsed_name = parseInt(conversation.acquaintance_id) === parseInt(idUser) ? conversation.user_name : conversation.acquaintance_name;
        const name = parsed_name == null ? "Manager " + conversation.office_name : parsed_name;

        return name
    }

    useEffect(() => {
        setSortedConversations(conversations)
    }, [conversations])

    return (
        <>
            <div ref={conversationRef} className="container-fluid conversation-container">
                <div className="row">
                    <p className="mt-2 pt-3 conversation-title">Mes conversations</p>
                    <button onClick={handleClose} className="close-conversation-container"></button>
                </div>
                <div className="conversation-search">
                    <Icon icon={Icon.icon.Search} />
                    <input type="text" className="conversation-search-input" onKeyUp={handleSearchKeyUp} />
                </div>
                {sortedConversations.map(function (conversation) {
                    const profile_picture = parseInt(conversation.acquaintance_id) === parseInt(idUser) ? conversation.user_photo : conversation.acquaintance_photo;
                    conversation.profile_picture = profile_picture
                    return (
                        <ConversationItem
                            key={conversation.id}
                            name={getConversationName(conversation)}
                            setActiveConversationName={setActiveConversationName}
                            conversation={conversation}
                            setActiveConversation={setActiveConversation}
                            idUserToNotify={idUser}
                        />
                    )
                })}
            </div>
            <div className={`container-fluid conversation-container-mobile`}>
                <img ref={conversationMobileRef} src="../static/icons/users-list.svg" alt="user list" onClick={(e) => handleClick(e)} />
            </div>
        </>
    )
}

export default ConversationList;