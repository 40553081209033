import React, { Component } from 'react'
import { connect } from 'react-redux'

import {
  actions as CourseTemplateActions,
  selectors as CourseTemplateSelectors,
} from '../../redux/CourseTemplateRedux'
import {
  actions as NavigationActions,
  selectors as NavigationSelectors,
} from '../../redux/NavigationRedux'
import {
  actions as BeneficiaryActions,
  selectors as BeneficiarySelectors,
} from '../../redux/BeneficiaryRedux'
import Page from '../../containers/global/Page/Page'
 
import AsideModuleSelector from '../../containers/course/AsideModuleSelector/AsideModuleSelector'
import Droppable from '../../containers/dragdrop/Droppable/Droppable'
import Draggable from '../../containers/dragdrop/Draggable/Draggable'
import EditStepWrapper from '../../containers/course/EditStepWrapper/EditStepWrapper'

import ParcoursHeader from '../../components/molecules/ParcoursHeader/ParcoursHeader'
import ConsultantBeneficiary from '../../containers/consultant/ConsultantBeneficiary/ConsultantBeneficiary'
import DescriptionTextArea from '../../components/molecules/DescriptionTextArea/DescriptionTextArea'
import { stepDone } from '../../helpers/courseHelper'
import PrimaryButton from '../../components/atoms/PrimaryButton/PrimaryButton'
import { actions as CourseActions } from '../../redux/CourseRedux'
import "./ConsultantBeneficiaryCourseEditPage.scss"
const mapStateToProps = (state, props) => ({
  module_id: NavigationSelectors.query(state, 'module_id'),
  step_index: NavigationSelectors.query(state, 'step_index'),
  user_id: props.match.params.user_id,
  course_id: props.match.params.course_id,
  template: CourseTemplateSelectors.template(state),
  pathname: NavigationSelectors.pathname(state),
  beneficiary: BeneficiarySelectors.beneficiary(state),
  hasChanged: CourseTemplateSelectors.hasChanged(state),
})

const mapDispatchToProps = (dispatch) => ({
  saveTemplate: (template) => dispatch(CourseTemplateActions.saveTemplate(template)),
  updateStep: (stepIndex, value) =>
    dispatch(CourseTemplateActions.updateStepAtIndex(stepIndex, value)),
  getBeneficiary: (id) => dispatch(BeneficiaryActions.getBeneficiary(id)),
  getTemplate: (id, user_id) => dispatch(CourseTemplateActions.getTemplate(id, user_id)),
  setTemplate: (template) => dispatch(CourseTemplateActions.setTemplate(template)),
  updateTemplateValue: (key, value) =>
    dispatch(CourseTemplateActions.updateTemplateValue(key, value)),
  back: () => dispatch(NavigationActions.back()),
  redirect: (pathname) => dispatch(NavigationActions.push(pathname)),
  exportCourse: (id) => dispatch(CourseActions.exportCourse(id)),
})

class ConsultantBeneficiaryCourseEditPage extends Component {

  state = {
    openStep: 0,
  }

  handleSave = () => {
    const { saveTemplate, template } = this.props

    saveTemplate(template)
  }

  checkForChanges = () => {
    return !this.props.hasChanged
  }

  handleStepToggle = (id) => {
    const openStep = this.state.openStep === id ? '' : id

    this.props.updateTemplateValue('openStep', openStep)
    this.setState(({ openStep }) => ({
      openStep,
    }))
  }

  handleExportCourse = () => {
    const { course_id: id } = this.props
    this.props.exportCourse(id)
  }

  checkProgress = (values) => {
    return values.every((value) => value.done === false) ? 'untouched' : 'ongoing'
  }

  handleChange = ({ id, value }) => {
    this.props.updateTemplateValue(id, value)
  }

  renderDescription = () => {
    const { template } = this.props
    const { description } = template

    return (
      <div className="course-description">
        <DescriptionTextArea
          // maxLength={500}
          name="description"
          placeholder={"Description du parcours"}
          onChange={this.handleChange}
          value={description}
        />
      </div>
    )
  }

  renderCounts = () => {
    const { template } = this.props
    const { steps } = template
    const moduleCount = steps.reduce((sum, { modules }) => sum + modules.length, 0)

    return (
      <div className="course-count">
        <div>
          {steps.length} <span>{steps.length > 1 ? "Etapes" : "Etape"}</span>
        </div>
        <div>
          {moduleCount} <span>{moduleCount > 1 ? "Modules" : "Module"}</span>
        </div>
      </div>
    )
  }

  renderSteps = () => {
    const { template, module_id } = this.props
    let prevStep

    const cards = template.steps.map((step, i) => {
      const { id, _id, modules } = step
      const key = (id || _id).toString()
      const isDone = stepDone(step)
      let progState = 'finish'
      let locked = false

      if (!isDone && !prevStep) {
        progState = this.checkProgress(modules)
        prevStep = true
      } else if (prevStep) {
        progState = 'locked'
        locked = true
      }

      return (
        <Draggable key={key} id={key} index={i}>
          <EditStepWrapper
            module_id={module_id}
            open={this.state.openStep === i}
            onCollapseClick={this.handleStepToggle}
            editOngoing
            progState={progState}
            stepIndex={i}
            step={modules}
            locked={locked}
          />
        </Draggable>
      )
    })

    return (
      <Droppable id="course-steps" className="steps" type="step">
        {cards}
      </Droppable>
    )
  }

  renderContent = () => {
    const { template } = this.props
    const { steps, id, title } = template
    const count = steps.reduce((acc, step) => acc + (step.done ? 1 : 0), 0)
    let icon = 'ongoing'

    if (count === steps.length) {
      icon = 'finish'
    } else if (count === 0) {
      icon = this.checkProgress(steps[0].modules)
    }

    return (
      <ParcoursHeader
        noChevron
        progState={icon}
        id={id}
        open
        title={title}
        editable
        placeholder={"Parcours"}
        onChange={this.handleChange}
      >
        <div className="course-content">
          {this.renderCounts()}
          {this.renderDescription()}
          {this.renderExportButton()}
          {this.renderSteps()}
        </div>
      </ParcoursHeader>
    )
  }

  renderExportButton = () => {
    return (
      <div className="export-button d-none">
        <PrimaryButton
          label={"Exporter"}
          onClick={this.handleExportCourse.bind(this)}
          id="export-button"
        />
      </div>
    )
  }

  renderAside = () => {
    return <AsideModuleSelector />
  }

  componentDidMount() {
    const { course_id, user_id, template, step_index } = this.props
    const exists = template && template.id.toString() === course_id.toString()

    this.props.getBeneficiary(user_id)


    if (!exists) {
      this.props.getTemplate(course_id, user_id)
    }

    if (step_index) {
      this.setState({ openStep: Number(step_index) })
    }
  }

  componentDidUpdate() {
    const { template } = this.props
    const { openStep: prevOpenStep } = this.state
    let openStep = ''

    if (template) {
      openStep = typeof template.openStep === 'undefined' ? 0 : template.openStep

      if (prevOpenStep !== openStep) {
        this.setState({
          openStep,
        })
      }
    }
  }

  render() {
    const { beneficiary, template, course_id } = this.props

    if (!beneficiary || !template || template.id.toString() !== course_id.toString()) {
      return null
    }

    return (
      <ConsultantBeneficiary
        renderAside={this.renderAside}
        disableSave={this.checkForChanges()}
        onSave={this.handleSave}
      >
        <div className="consultant-beneficiary-course-edit-page">{this.renderContent()}</div>
      </ConsultantBeneficiary>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Page(ConsultantBeneficiaryCourseEditPage))