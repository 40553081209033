import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux'
import { selectors as UserSelectors } from '../../redux/UserRedux'
import { selectors as NavigationSelectors } from "../../redux/NavigationRedux";

import { getClient, updateClient } from "../../api/ClientsApi";

import ConsultantPageAside from "../../containers/consultant/ConsultantPageAside/ConsultantPageAside";
import { ClientContainer } from "../../containers/clients/ClientContainer/ClientContainer";
import { ClientMissionsContainer } from "../../containers/clients/ClientMissionsContainer/ClientMissionsContainer";
import { ClientTalentsContainer } from "../../containers/clients/ClientTalentsContainer/ClientTalentsContainer";
import LeftAsideLayout from "../../layouts/LeftAsideLayout/LeftAsideLayout";
import LineMenu from "../../components/organisms/LineMenu/LineMenu";
import ManagerPageAside from "../../containers/manager/ManagerPageAside/ManagerPageAside";
import PrimaryButton from "../../components/atoms/PrimaryButton/PrimaryButton";
import TabbedContentLayout from "../../layouts/TabbedContentLayout/TabbedContentLayout";
import { IconBack } from "../../components/atoms/IconBack/IconBack";
import { Toaster } from "../../components/atoms/Toaster/Toaster";

import { useToaster } from "../../hooks/useToaster";
import './ConsultantClientPage.scss';

export const ConsultantClientPage = () => {
    const user = useSelector(UserSelectors.user);
    const [client, setClient] = useState();
    const [talents, setTalents] = useState([])
    const [disabledSaveChangesButton, setDisabledSaveChangesButton] = useState(true);
    const [isValidClient, setIsValidClient] = useState();
    const [tab, setTab] = useState("talents");
    const path = useSelector(NavigationSelectors.lastParam);
    const { toasters, addToaster, removeToaster } = useToaster();
    const [isEditable, setIsEditable] = useState();
    const handleTabsOnChange = (tab) => {
        setTab(tab);
    }

    const handleIsClientCompleted = (isClientCompleted) => {
        setIsValidClient(isClientCompleted);
        setDisabledSaveChangesButton(!isClientCompleted);
    }

    const getClientUpdated = (clientUpdated) => {
        clientUpdated !== client ? setClient(clientUpdated) : setDisabledSaveChangesButton(true)
    }

    const handleSaveChangesOnClick = () => {
        updateClient(client.id, client)
            .then((response) => {
                setClient(response.group);
                addToaster("Client mis à jour", `Le client ${response.group.name} a été mis à jour avec succès`, "success")
            })
            .catch(() => {
                addToaster("Erreur", `Une erreur est survenue lors de la mise à jour du client ${client.name}`, "error")
            });
    }

    useEffect(() => {
        getClient(+path).then((response) => {
            setClient(response.client)
        })
    }, [path])

    useEffect(() => {
        if (client) {
            client.missions ? getTalents(client.missions) : getTalents([])
        }
    }, [client])

    useEffect(() => {
        if (client) {
            if (user.id === client?.responsable[0]?.id) {
                setIsEditable(true)
            }
            else setIsEditable(false)

        }
    }, [user, client])

    const getTalents = (missions) => {
        const all_talents = [];
        missions.forEach((mission) => {
            if (mission.talents) {

                mission.talents.forEach((t) => {
                    const talentIndex = all_talents.findIndex((talent) => talent.id === t.id);

                    if (talentIndex === -1) {
                        all_talents.push({
                            id: t.id,
                            first_name: t.first_name,
                            last_name: t.last_name,
                            phone: t.phone,
                            email: t.email,
                            missions: [
                                {
                                    id: mission.id,
                                    name: mission.name,
                                },
                            ],
                        });
                    } else {
                        all_talents[talentIndex].missions.push({
                            id: mission.id,
                            name: mission.name,
                        });
                    }
                });
            }
        });

        setTalents(all_talents)
    }

    const renderTalents = () => {
        return <ClientTalentsContainer client={client} talentsProps={talents} />
    }

    const renderMissions = () => {
        return <ClientMissionsContainer missionsprops={client.missions} />
    }

    const renderClientInformation = () => {
        return (
            <div className="client-informations">
                {isEditable &&
                    <PrimaryButton label={"Sauvegarder les modifications"} disabled={disabledSaveChangesButton} onClick={handleSaveChangesOnClick} />
                }
                <ClientContainer clientProps={client} isClientCompleted={handleIsClientCompleted} sendClient={getClientUpdated} />
                {!isValidClient && <p className="alert-invalid-client">*Les champs obligatoires n'ont pas été remplis. Veuillez fournir le nom du client, le consultant responsable, le prénom et le nom.</p>}
            </div>
        )
    }

    const renderContent = () => {
        if (tab === "talents" && client && talents) return renderTalents()
        if (tab === "missions" && client) return renderMissions()
        if (tab === "info") return renderClientInformation()
    }
    const renderTabs = () => {

        const tabs = [{ id: "talents", label: "Talents" }, { id: "missions", label: "Missions" }, { id: "info", label: "Informations du client" }]
        return <LineMenu value={tab} links={tabs} onChange={handleTabsOnChange} />
    }

    const aside = user.role === "consultant" ? <ConsultantPageAside /> : <ManagerPageAside />
    const content = () => {
        return (
            <div className="consultant-client-page">
                <div className="left-navbar">
                    <IconBack iconColor={"accent"} isText={true} />
                </div>
                <TabbedContentLayout header={renderTabs()} content={renderContent()} />
            </div>
        )
    }

    return (
        <div className="page">
            <div>
                <LeftAsideLayout aside={aside} content={content()} />
            </div>
            <div className="notification-list">
                {toasters.map(toaster => <Toaster key={toaster.id} title={toaster.title} message={toaster.message} type={toaster.type} onClose={() => removeToaster(toaster.id)} />)}
            </div>
        </div>
    )
}