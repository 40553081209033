/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectors as UserSelectors } from '../../redux/UserRedux';

import { deleteMission, getMissionsByConsultant } from '../../api/MissionsApi';
import { getAllClients } from '../../api/ClientsApi';

import BigCombo from '../../components/molecules/BigCombo/BigCombo';
import CircleButton from '../../components/atoms/CircleButton/CircleButton';
import ConsultantPageAside from '../../containers/consultant/ConsultantPageAside/ConsultantPageAside';
import { DeleteMissionPopin } from '../../containers/popins/DeleteMissionPopin/DeleteMissionPopin';
import Icon from '../../components/atoms/Icon/Icon';
import LeftAsideLayout from '../../layouts/LeftAsideLayout/LeftAsideLayout';
import ManagerPageAside from '../../containers/manager/ManagerPageAside/ManagerPageAside';
import { MissionsList } from '../../containers/missions/MissionsList/MissionsList';
// import PrimaryButton from "../../components/atoms/PrimaryButton/PrimaryButton";
import SearchField from '../../components/molecules/SearchField/SearchField';
import { Toaster } from '../../components/atoms/Toaster/Toaster';

import { useNavigation } from '../../hooks/useNavigation';
import { useLocation } from 'react-router-dom';
import { useToaster } from '../../hooks/useToaster';

import './ConsultantMissionsPage.scss';
import { containsSearchValue } from '../../api/utils/StringUtils';

export const ConsultantMissionsPage = () => {
  const user = useSelector(UserSelectors.user);
  const { pathname, redirect } = useNavigation();
  const [missions, setMissions] = useState([]);
  const location = useLocation();
  const newMission = location.state ? location.state.newMission : null;
  const [showDeleteMissionModal, setShowDeleteMissionModal] = useState(false);
  const [missionDeleted, setMissionDeleted] = useState();
  const [searchQuery, setSearchQuery] = useState('');
  const [allMissions, setAllMissions] = useState([]);
  const [clients, setClients] = useState([]);
  const { toasters, addToaster, removeToaster } = useToaster();

  const handleAddNew = () => {
    redirect(`${pathname}/new`);
  };

  // const handleExportMissions = () => {}

  const handleConfirmDeleteMission = (deletedMission) => {
    setMissionDeleted(deletedMission);
    setShowDeleteMissionModal(true);
  };

  const handleDeleteMission = () => {
    setShowDeleteMissionModal(false);
    const filteredMissions = missions.filter((mission) => mission.id !== missionDeleted.id);
    setMissions(filteredMissions);
    deleteMission(missionDeleted.id)
      .then(() => {
        addToaster(
          'Mission suprimée',
          `La mission ${missionDeleted.name} a été supprimée avec succès`,
          'success',
        );
      })
      .catch(() => {
        addToaster(
          'Erreur',
          `Une erreur est survenue lors de la suppression de la mission ${missionDeleted.name}`,
          'error',
        );
      });
  };

  const handleSearchOnChange = (search) => {
    const searchValue = search.value;
    setSearchQuery(searchValue);

    const filteredMissions = allMissions.filter((mission) => {
      const missionResponsables = mission.responsable || [];

      const isAnyResponsableMatching = missionResponsables.some((responsable) => {
        return containsSearchValue(responsable.first_name, searchValue) || containsSearchValue(responsable.last_name, searchValue);
      });

      return isAnyResponsableMatching || containsSearchValue(mission.name, searchValue);
    });
    setMissions(filteredMissions.sort((a, b) => a.name.localeCompare(b.name)));
  };

  const handleOnChangeDropdown = (dropdown) => {
    const { value } = dropdown;
    if (value.value !== 'all') {
      const filteredMissions = allMissions.filter((mission) => mission.client_id === value.value);
      setMissions(filteredMissions);
    } else {
      setMissions(allMissions);
    }
  };

  useEffect(() => {
    getMissionsByConsultant(user.id).then((response) => {
      if (response) {
        setMissions(response.missions.sort((a, b) => a.name.localeCompare(b.name)));
        setAllMissions(response.missions);
      }
    });

    getAllClients(user.id).then((response) => {
      const all_clients = response.length <= 0 ? [] : response.clients.map((client) => {
        return {
          value: client.id,
          label: client.name,
        };
      });

      const all = { value: 'all', label: 'Tous' };
      all_clients.unshift(all);

      setClients(all_clients);
    });
  }, []);

  useEffect(() => {
    if (newMission) {
      setMissions([...missions, newMission]);
      addToaster(
        'Mission ajoutée',
        `La mission ${newMission.name} a été ajoutée avec succès`,
        'success',
      );
    }
  }, [newMission]);

  const renderMissions = () => {
    return (
      <div className="consultant-missions-content">
        <div className="consultant-missions-header">
          <CircleButton icon={'plus'} onClick={handleAddNew} />
          {/* <PrimaryButton label={"Exporter"} onClick={handleExportMissions} /> */}
        </div>
        <div className="consultant-missions-filter">
          <div className="consultant-missions-search-bar">
            <Icon icon={'sliders'} color={'accent'} />
            <SearchField
              placeholder={"Rechercher par nom d'une mission ou responsable"}
              value={searchQuery}
              onChange={handleSearchOnChange}
            />
          </div>
          <div className="consultant-missions-dropdown">
            <BigCombo id="clients" options={clients} value={{ label: '', value: null }} onChange={handleOnChangeDropdown} />
          </div>
        </div>
        {missions && missions.length > 0 ? (
          <MissionsList
            missions={missions}
            isClickable={true}
            showClient={true}
            showTalents={true}
            isDeletable={true}
            handleDeleteMission={handleConfirmDeleteMission}
          />
        ) : (
          <p>Aucune mission enregistrée pour le moment...</p>
        )}
      </div>
    );
  };

  const aside = user.role === 'consultant' ? <ConsultantPageAside /> : <ManagerPageAside />;

  return (
    <div className="page">
      <div className="consultant-missions-page">
        <LeftAsideLayout aside={aside} content={renderMissions()} />
        {showDeleteMissionModal && (
          <DeleteMissionPopin
            open={showDeleteMissionModal}
            onClose={() => setShowDeleteMissionModal(false)}
            mission={missionDeleted}
            handleDeleteMission={handleDeleteMission}
          />
        )}
      </div>
      <div className="notification-list">
        {toasters.map((toaster) => (
          <Toaster
            key={toaster.id}
            title={toaster.title}
            message={toaster.message}
            type={toaster.type}
            onClose={() => removeToaster(toaster.id)}
          />
        ))}
      </div>
    </div>
  );
};
