/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux'
import { selectors as UserSelectors } from '../../redux/UserRedux'

import { getAllClients, deleteClient } from "../../api/ClientsApi";

import CircleButton from "../../components/atoms/CircleButton/CircleButton";
import { ClientsList } from "../../containers/clients/ClientsList/ClientsList";
import ConsultantPageAside from "../../containers/consultant/ConsultantPageAside/ConsultantPageAside";
import { DeleteClientPopin } from "../../containers/popins/DeleteClientPopin/DeleteClientPopin";
import LeftAsideLayout from "../../layouts/LeftAsideLayout/LeftAsideLayout";
import ManagerPageAside from "../../containers/manager/ManagerPageAside/ManagerPageAside";
// import PrimaryButton from "../../components/atoms/PrimaryButton/PrimaryButton";
import Icon from "../../components/atoms/Icon/Icon";
import SearchField from "../../components/molecules/SearchField/SearchField";
// import { Navbar } from "../../components/atoms/Navbar/Navbar";
import { Toaster } from "../../components/atoms/Toaster/Toaster";

import { useNavigation } from "../../hooks/useNavigation";
import { useToaster } from "../../hooks/useToaster";
import { useLocation } from 'react-router-dom';

import './ConsultantMyClientsPage.scss';
import { containsSearchValue } from "../../api/utils/StringUtils";

export const ConsultantMyClientsPage = () => {
    const user = useSelector(UserSelectors.user);
    const { pathname, redirect } = useNavigation();
    const [clients, setClients] = useState([]);
    const [allClients, setAllClients] = useState([]);
    const location = useLocation();
    const newClient = location.state ? location.state.newClient : null;
    const [showDeleteClientModal, setShowDeleteClientModal] = useState(false);
    const [clientDeleted, setClientDeleted] = useState();
    const [searchQuery, setSearchQuery] = useState('');
    const { toasters, addToaster, removeToaster } = useToaster();

    const handleAddNew = () => {
        redirect(`${pathname}/new`)
    }

    // const handleExportClients = () => { }

    const handleConfirmDeleteClient = (deletedClient) => {
        setClientDeleted(deletedClient);
        setShowDeleteClientModal(true)
    }

    const handleDeleteClient = () => {
        setShowDeleteClientModal(false);
        const filteredClients = clients.filter(mission => mission.id !== clientDeleted.id);
        setClients(filteredClients);
        deleteClient(clientDeleted.id)
            .then(() => {
                addToaster("Client supprimé", `Le client ${clientDeleted.name} a été supprimé avec succès`, "success")
            })
            .catch(() => {
                addToaster("Erreur", `Une erreur est survenue lors de la suppression du client ${clientDeleted.name}`, "error")
            });
    }

    const getClientsConsultant = () => {
        getAllClients(user.id).then((response) => {
            setClients(response.clients);
            setAllClients(response.clients);
        })
    }

    const getClientsManager = () => {
        getAllClients().then((response) => {
            setClients(response.clients);
            setAllClients(response.clients);
        })
    }

    const handleSearchOnChange = (search) => {
        const searchValue = search.value;
        setSearchQuery(searchValue);

        const filteredClients = allClients.filter(mission => {
            return containsSearchValue(mission.name, searchValue);
        });

        setClients(filteredClients);
    }

    useEffect(() => {
        user.role === "manager" ? getClientsManager() : getClientsConsultant();
    }, [])

    useEffect(() => {
        if (newClient) {
            setClients([...clients, newClient])
            addToaster("Client ajouté", `Le client ${newClient.name} a été ajouté avec succès`, "success")
        }

    }, [newClient])

    const renderClients = () => {
        return (
            <div className="consultant-clients-content">

                <div className="consultant-clients-header">
                    <CircleButton icon={"plus"} onClick={handleAddNew} />
                    {/* <PrimaryButton label={"Exporter"} onClick={handleExportClients} /> */}
                </div>
                <div className="consultant-clients-filters">
                    <div className="consultant-clients-search-bar">
                        <Icon icon={"sliders"} color={'accent'} />
                        <SearchField placeholder={"Rechercher par nom d'un client"} value={searchQuery} onChange={handleSearchOnChange} />
                    </div>
                </div>
                {(clients && clients.length > 0)
                    ? <ClientsList clients={clients} handleDeleteClient={handleConfirmDeleteClient} />
                    : <p>Aucun client enregistré pour le moment</p>
                }
            </div>
        )
    }

    const renderContent = () => {
        return renderClients();
    }

    const aside = user.role === "consultant" ? <ConsultantPageAside /> : <ManagerPageAside />

    return (
        <div className="page">
            <div className="consultant-clients-page">
                <LeftAsideLayout aside={aside} content={renderContent()} />
                {showDeleteClientModal && <DeleteClientPopin open={showDeleteClientModal} onClose={() => setShowDeleteClientModal(false)} client={clientDeleted} handleDeleteClient={handleDeleteClient} />}
            </div>
            <div className="notification-list">
                {toasters.map(toaster => <Toaster key={toaster.id} title={toaster.title} message={toaster.message} type={toaster.type} onClose={() => removeToaster(toaster.id)} />)}
            </div>
        </div>
    )
}