import React, { useEffect, useState, useRef } from "react";
import MessageItem from "./MessageItem";

const MessageList = ({ messages, idUser, handleDeleteMessage }) => {
    const containerMessage = useRef(null);
    const [messagesList, setMessagesList] = useState([]);

    useEffect(() => {
        setMessagesList(messages);
        scrollToBottom("smooth", 100);

    }, [messages])

    const scrollToBottom = (behavior, delay) => {
        if (containerMessage.current) {
            const container = containerMessage.current;
            setTimeout(() => {
                container.scrollTo({
                    top: container.scrollHeight,
                    behavior: behavior,
                });
            }, delay)
        }
    };

    return (
        <div ref={containerMessage} className="container container-message ms-4 mt-4">
            {messagesList.length === 0 &&
                <div className="no-messages">
                    <p>Aucun message<br />dans cette conversation</p>
                </div>}
            {messagesList && messagesList.map(function (message) {
                return (
                    <MessageItem key={message.id ? message.id : message.random_key} message={message} type={parseInt(message.id_author) === parseInt(idUser) ? "in" : "out"} onDeleteMessage={() => handleDeleteMessage(message)} />
                )
            })}
        </div>
    )
}

export default MessageList;