import React, { useEffect, useState } from "react";
import { updateRoomNotification } from "../../api/ChatApi";

const ConversationItem = ({ name, setActiveConversationName, setActiveConversation, conversation, idUserToNotify }) => {
    const [redDot, setRedDot] = useState(false);
    const chatNotification = localStorage.getItem("mtChatNotification");

    const handleClick = (event) => {
        setActiveConversation(conversation);
        setActiveConversationName(name);

        const conversationList = document.getElementsByClassName("conversation-container")[0];
        const conversationListMobile = document.getElementsByClassName("conversation-container-mobile")[0];

        conversationList.classList.remove("conversation-mobile");
        conversationListMobile.classList.remove("conversation-mobile");

        if (redDot) {
            setRedDot(false);
            const parsedLocalStorage = JSON.parse(chatNotification);
            const localIdReceiver = parsedLocalStorage.id_receiver
            let roomKeysArray = [];

            if (chatNotification !== null) {
                roomKeysArray = parsedLocalStorage.room_keys;
            }

            const filteredRoomKeyArray = roomKeysArray.filter((item) => item !== conversation.room_key);

            if (filteredRoomKeyArray.length === 0) {
                localStorage.removeItem("mtChatNotification");
            } else {
                const obj = {
                    room_keys: filteredRoomKeyArray,
                    id_receiver: localIdReceiver,
                    notification: true
                }

                localStorage.setItem("mtChatNotification", JSON.stringify(obj));
            }

            updateRoomNotification(conversation.room_key, false)
        }
    }

    useEffect(() => {
        if (chatNotification !== null) {

            const parsedLocalStorage = JSON.parse(chatNotification);

            if (parsedLocalStorage.notification === true) {
                addRedDot(parsedLocalStorage.room_keys, parsedLocalStorage.id_receiver, idUserToNotify);
            }
        }
    }, [chatNotification]);

    const addRedDot = (room_keys, id_receiver, idUserToNotify) => {
        if (room_keys.includes(conversation.room_key) && id_receiver === idUserToNotify) {
            setRedDot(true);
        }
    }

    const getConversationPhoto = (conversation, idUser) => {
        const photo = parseInt(conversation.acquaintance_id) === parseInt(idUser) ? conversation.user_photo : conversation.acquaintance_photo;

        if (!photo) {
            return "../static/icons/person-missing.svg"
        }

        return `${process.env.REACT_APP_ASSETS_API_STATIC_URL}${(photo).replace(process.env.REACT_APP_OLD_UPLOADS_API_STATIC_URL, '')}`
    }

    const convertLastMessageString = (lastMessage, lastMessageType, lastMessageIsDeleted) => {
        if (!lastMessage) {
            return "Nouveau chat"
        }

        if (lastMessageIsDeleted) {
            return "<Supprimé...>"
        }

        if (lastMessageType === "text" || lastMessageType === "visio") {
            return lastMessage
        }

        return "<Fichier...>"
    }

    return (
        <div className="conversation-item mt-4" onClick={(event) => handleClick(event)}>
            <img style={{ objectFit: "cover" }} src={getConversationPhoto(conversation, idUserToNotify)} alt="user" />
            <div className="conversation-item-content">
                <p className={`conversation-item-p conversation-item-name`}>{name !== null ? name : "\u00A0"}</p>
                <div className="conversation-item-separator"></div>
                <p className={`conversation-item-p conversation-item-last-message text-muted`}>{convertLastMessageString(conversation.last_message, conversation.last_message_type, conversation.last_message_is_deleted)}</p>
                {redDot &&
                    <div className="red-dot-conversation-item"></div>
                }
            </div>
        </div>
    )
}

export default ConversationItem;