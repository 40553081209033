import React, { Component, Fragment } from 'react';

import { Popin } from '../../../containers/global/Popin/Popin';

// Components
import IconButton from '../../atoms/IconButton/IconButton';
import Icon from '../../atoms/Icon/Icon';

import EditSynthesisSectionPopin from './EditSynthesisSectionPopin/EditSynthesisSectionPopin';

class AISynthesisSection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      synthesis: props.synthesis,
      sectionId: props.sectionId,
      openEdit: false,
    };

    this.handleUpdateSynthesis = this.handleUpdateSynthesis.bind(this);
  }

  getSectionById(section_id) {
    const { synthesis, sectionId } = this.state;

    const resultSection = synthesis.aiSynthesisSection.find(
      (section) => section.id === section_id || section.id === sectionId,
    );

    return resultSection;
  }

  handleOpenEdit() {
    // const { openEdit } = this.state;
    this.setState({ openEdit: !this.state.openEdit });
  }

  handleUpdateSynthesis(updatedSynthesis) {
    this.setState({ synthesis: updatedSynthesis }, () => {
      if (this.props.handleUpdate) {
        this.props.handleUpdate(updatedSynthesis);
      }
    });
  }

  render() {
    const { sectionId, synthesis, openEdit } = this.state;
    const section = this.getSectionById(sectionId);

    return (
      <Fragment key={'section_' + sectionId}>
        <div key={section.sectionId} className="presentation-card">
          <div className="icon-card-title" style={{ justifyContent: 'space-between' }}>
            <h1 className="label">{section.sectionCode}</h1>
            <div style={{ display: 'flex', gap: '1rem' }}>
              {section.modifiedText !== section.generatedText && <Icon icon={Icon.icon.Finish} />}

              <IconButton
                icon={Icon.icon.Edit}
                color={Icon.color.Accent}
                onClick={() => this.handleOpenEdit()}
              />
            </div>
          </div>
          <div>
            <div dangerouslySetInnerHTML={{ __html: section.modifiedText }} />
          </div>
        </div>

        <Popin
          id="popin"
          classes={'popin-iasynthesis-edit'}
          children={
            <EditSynthesisSectionPopin
              synthesis={synthesis}
              sectionId={sectionId}
              onClose={() => this.handleOpenEdit()}
              onUpdate={this.handleUpdateSynthesis}
            />
          }
          focusTrapped={false}
          onClose={() => this.handleOpenEdit()}
          open={openEdit}
        />
      </Fragment>
    );
  }
}

export default AISynthesisSection;
