import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Icon from '../../atoms/Icon/Icon'
import { IconBackground } from '../../atoms/IconBackground/IconBackground'
import './ParcoursCard.scss';

const ParcoursCard = ({
  course,
  onEditClick,
  onCloneClick,
  onRemoveClick,
  onCardClick,
  onPreviewClick,
  onTogglePublishClick,
}) => {

  const { id, title, coverUrl, stepsCount, modulesCount, percentage_done } = course
  const [image, setImage] = useState(coverUrl ? coverUrl : '/static/images/course-default-background.jpg');
  const locale = {
    steps: "Etapes",
    modules: "Modules",
    edit: "Editer",
    clone: "Dupliquer",
    remove: "Supprimer",
    preview: "Vue talent",
    publish: "Publier",
    unpublish: "Dé-publier",
  }

  useEffect(() => {
    if (course) {
      setImage(
        coverUrl ? coverUrl : '/static/images/course-default-background.jpg',
      );
    }
  }, [course]);

  const handleEditClick = () => {
    onEditClick({ id })
  }

  const handleCloneClick = () => {
    onCloneClick({ id })
  }

  const handleRemoveClick = () => {
    onRemoveClick({ id })
  }

  const handlePreviewClick = () => {
    onPreviewClick({ id })
    onCardClick({ id })
  }

  const handleCardClick = () => {
    // handleEditClick();
    onCardClick({ id })
  }

  const truncateString = (fullStr) => {
    if (!fullStr) return
    const strLen = 95
    if (fullStr.length <= strLen) return fullStr

    const separator = '...'
    const sepLen = separator.length
    const charsToShow = strLen - sepLen

    return fullStr.substr(0, charsToShow) + separator
  }

  const renderActions = () => {
    const {
      isEditable,
      isCloneable,
      // isRemovable,
      canPreview,
      canPublish,
      published,
      canDelete,
      isPublishable,
    } = course


    const options = [
      canPreview && { key: 'preview', icon: Icon.icon.Preview2, handler: handlePreviewClick },
      isEditable && { key: 'edit', icon: Icon.icon.Edit2, handler: handleEditClick },
      isCloneable && { key: 'clone', icon: Icon.icon.Copy, handler: handleCloneClick },
      canDelete && { key: 'remove', icon: Icon.icon.Trash2, handler: handleRemoveClick },
      isPublishable &&
      canPublish &&
      !published && {
        key: 'publish',
        icon: Icon.icon.Publish,
        handler: onTogglePublishClick,
        color: Icon.color.Accent,
      },
      isPublishable &&
      canPublish &&
      published && {
        key: 'unpublish',
        icon: Icon.icon.FreeActive,
        handler: onTogglePublishClick,
        color: Icon.color.Orange1,
      },
    ]

    const actions = options
      .filter((action) => action)
      .map(({ key, icon, handler }) => (
        <div className="action-button" key={key} onClick={handler}>
          <Icon icon={icon} color={icon.color || Icon.color.Grey2} />
          <span>{locale[key]}</span>
        </div>
      ))

    if (actions.length === 0) {
      return null
    }

    return <div className="actions">{actions}</div>
  }

  const width = percentage_done;
  let backgroundColor = "var(--red-medium)";

  return (
    <div className="parcours-card">
      <div className="parcours-card-inner" onClick={handleCardClick}>
        <div
          className="parcours-image"
          style={coverUrl ? { backgroundImage: `url("${image}")` } : { backgroundColor: backgroundColor }}
        />

        <div className="content">
          <div className="label">
            <span>{truncateString(title)}</span>
          </div>

          <div className="course-informations">
            <div className="entry">
              <IconBackground icon="clipboard" color="accent" />
              <div className='course-informations-label'>
                {
                  stepsCount > 1
                    ? <label className="entry-label">{locale.steps}</label>
                    : <label className="entry-label">Etape</label>
                }
                <p className="entry-count">{stepsCount}</p>
              </div>
            </div>

            <div className="entry">
              <IconBackground icon="grid" color="accent" />
              <div className='course-informations-label'>
                {
                  modulesCount > 1
                    ? <label className="entry-label">{locale.modules}</label>
                    : <label className="entry-label">Module</label>
                }
                <p className="entry-count">{modulesCount}</p>

              </div>
            </div>
          </div>

          {percentage_done >= 0 ?
            <div className='pourcentage-done-course'>
              <span>Avancement : <p> {percentage_done} %</p></span>
              <div className='progression-bar'>
                <div className='progression-done-bar' style={{ width: `${width}%` }}></div>
              </div>
            </div> : ""
          }
        </div>
      </div>
      {renderActions()}
    </div>
  )
}

ParcoursCard.propTypes = {
  course: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    coverUrl: PropTypes.string,
    title: PropTypes.string.isRequired,
    stepsCount: PropTypes.number.isRequired,
    modulesCount: PropTypes.number.isRequired,
    published: PropTypes.bool,
    isEditable: PropTypes.bool,
    isRemovable: PropTypes.bool,
    isCloneable: PropTypes.bool,
    isPublishable: PropTypes.bool,
    canPreview: PropTypes.bool,
    canPublish: PropTypes.bool,
    percentage_done: PropTypes.number,
  }),
  onEditClick: PropTypes.func,
  onCloneClick: PropTypes.func,
  onRemoveClick: PropTypes.func,
  onPreviewClick: PropTypes.func,
  onCardClick: PropTypes.func,
}

ParcoursCard.defaultProps = {
  isRemovable: false,
  isCloneable: false,
  isPublishable: false,
  published: false,
  canPreview: false,
  canPublish: false,
  onEditClick: ({ id }) => undefined,
  onCloneClick: ({ id }) => undefined,
  onRemoveClick: ({ id }) => undefined,
  onPreviewClick: ({ id }) => undefined,
  onCardClick: () => {
  },
}
export default ParcoursCard
